import React, { useState, useEffect } from "react"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Utils from "../../Helpers/Utils"
import { connect } from "react-redux"
import BootstrapTooltip from "../../../../globals/BootstrapTooltip"

import Styles from "./leadstatus.module.css"
import { Skeleton } from "@material-ui/lab"
import { Box, FormControl, InputBase, Typography, withStyles } from "@material-ui/core"
import { changeLeadOwner, getSubUserListForContactlist } from "../../../../../api/contactApi"
import { getSubUserList, setSubUserListFromAction } from "../../redux/contactAction"


const BasicInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "&.MuiInputBase-multiline": {
      padding: "0 !important",
    },
  },
  input: {
    position: "relative",
    fontSize: "14px !important",
    margin: "0 !important",
    height: "2.5rem !important",
    padding: "10px 24px 4px 10px !important",
    borderRadius: "4px !important",
    background: "white !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&.MuiSelect-select:focus": {
      background: "white !important",
    },
    "&.MuiSelect-selectMenu .subtitle": {
      display: "none",
    },
    "&.MuiSelect-selectMenu .selectedTitle": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      "@media(minWidth: 1300px)": {
        width: "100%",
      },
    },

    "&:focus": {
      borderRadius: 4,
      background: "white !important",
      boxShadow: "unset !important",
    },
  },
}))(InputBase)

const LeadOwnerCopy = (props) => {
  const [show, setShow] = useState(false)
  const [count, setCount] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [users, setUsers] = useState([])
  const [userId, setUserId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [ownerInfo, setOwnerInfo] = useState(null)
  const [data, setData] = useState(null)
  const [subUserList, setSubUserList] = useState(null)


   useEffect(() => {
     const tempUserId = Utils.getAccountData("userId")

     setUserId(tempUserId)
     setData(props.data)

     if (
       props.data?.user &&
       (props.data.user.id === tempUserId ||
         props.data.user.parent_id === tempUserId)
     ) {
       loadTeamUserList()
       if (props.data.user) {
         setOwnerInfo(props.data.user)
       }
     }
   }, [props.data])

  const loadTeamUserList = () => {
    if (props.subUserList != null) {
      setSubUserList(props.subUserList)
    } else {
      getSubUserListForContactlist()
        .then((response) => {
          props.setSubUserList(response.data.data)
          setSubUserList(response.data.data)
          setShow(true)
        })
        .catch((error) => {
          console.log("something is wrong" + error)
        })
    }
  }

  const handleSubmit = (user_id) => {
    // setLoading(true)

    changeLeadOwner({
      contact_id: props.contact.id,
      user_id: user_id,
    })
      .then((res) => {
        if (res.status === 200) {
          window.showNotification("SUCCESS", "Lead Owner Successfully Changed")
          setOwnerInfo({ ...ownerInfo, id: user_id })
        } else {
          window.showNotification(
            "ERROR",
            "Something was wrong, Please reload the page and try again"
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
   
   
    <Box className={Styles.leadOwnerBoxWidth}>
        {loading ? (
         
          <Skeleton variant="rectangular" width={152} height={60} />
        ) : (
         
          // ownerInfo && (
           
            <FormControl fullWidth sx={{ marginLeft: 2 }}>
              <Select
                value={ownerInfo?.id|| ''}
                onChange={(e) => handleSubmit(e.target.value)}
                //displayEmpty
                input={<BasicInput className="basicinputbackground" />}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem
                  className="dropdownhelper-menuitem-class"
                  value=""
                  disabled
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    style={{ lineHeight: 1 }}
                  >
                    Lead Owner
                  </Typography>
                </MenuItem>
                {subUserList?.map((user) => (
                  <MenuItem
                    key={user.id}
                    value={user.id}
                    className="dropdownhelper-menuitem-class"
                  >
                    <BootstrapTooltip title={user.full_name}>
                      <Typography
                        className={`${Styles.leadOwnerTextWidth} selectedTitle`}
                        variant="body1"
                        color="textSecondary"
                        style={{ lineHeight: 1.2 }}
                      >
                        {" "}
                        {user.full_name}
                      </Typography>
                    </BootstrapTooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          // ) 
        )}
      </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    allContactList: state.rightsideReducer.allContactList,
    userInfo: state.rightsideReducer.userInfo,
    subUserList: state.rightsideReducer.subUserList,
    contact: state.rightsideReducer.contact,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSubUserList: (params) => dispatch(setSubUserListFromAction(params)),
    getAllSubUsers: (params, callback) =>
      dispatch(getSubUserList(params, callback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadOwnerCopy)

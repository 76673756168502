import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import NewLoader from "./Helpers/NewLoader";
import LeadOwner from "./LeadOwner";
import Utils from "../../../helpers/Utils";
import DNC from "./dnc/DNC";
import Collaborators from "./collaborator/Collaborators";
import Tags from "./Tag/Tags";
import AssignToStage from "./deal/AssignToStage";
import TaskList from "./task/TaskList";
import CampaignList from "./campaignList/CampaignList";
import ContactNotes from "./notes";
import FormEntryList from "./formEntryList/FormEntryList";
import Source from "./Source";
import {
  fetchContactDetails,
  getContactSmartFormEntries,
} from "../../../api/contactApi";
import { fetchTasks, setContactDetails } from "./redux/contactAction";
import { toggleRightSidebar } from "../../../actions/inboxAction";
import { connect } from "react-redux";
import ContactInfo from "./contactInfo/ContactInfo";
import AssignToStageV2 from "./deal/AssignToStageV2";
import SkeletonLoaderMiscellaneous from "./SkeletonLoaderMiscellaneous";
import Policy from "./policy/Policy";
import Unsubscribed from "./Unsubscribed";
import { AGENCY_BUSINESS_TYPE } from "../../../constants/CoreConstants";

const Miscellaneous = (props) => {
  const [formEntries, setFormEntries] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setComponentLoading(true);
    if (props.data != null) {
      setComponentLoading(false);
      setData(props.data);
    } else {
      loadContactData().then();
    }
    getContactSmartFormEntries(props.contactId)
      .then((res) => {
        if (res && res.success) {
          setFormEntries(res.data);
        } else {
          setFormEntries([]);
        }
      })
      .catch((err) => {
        setFormEntries([]);
      });
  }, [props.contactId]);

  const loadContactData = async () => {
    let param = { id: props.contactId };
    if (Utils.getAccountData("carrierLookup")) {
      param["carrierLookup"] = true;
    }
    fetchContactDetails(param)
      .then((response) => {
        if (response.data.status === "success") {
          setData(response.data.data);
          props.setContactDetails(response.data.data["people"]);
        } else {
          window.showNotification("ERROR", response.data.message);
        }
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };

  const showNotificationStart = (type, message) => {
    window.showNotification(type, message);
  };

  return (
    <div>
      <div
        className="closeRightSidebar waves-effect"
        onClick={(e) => {
          e.preventDefault();
          props.toggleRightSidebar();
        }}
      >
        <Close />
      </div>
      {componentLoading || data == null || props.contact == null ? (
        <SkeletonLoaderMiscellaneous />
      ) : (
        <React.Fragment>
          {/* <LeadOwner reloadTopSection={() => {}} data={data} /> */}
          <ul className="collapsible " style={{ border: "none", boxShadow: "none" }}>
          {Utils.getAccountData("agencyBusinessType") &&
              Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE && (
                <li className="design-modify-inbox-module-right-side collaborat__list common__list v2_single__contact_campaigns inboxAccordionIcon">
                  <Policy />
                </li>
              )}
            <li className="design-modify-inbox-module-right-side collaborat__list common__list inboxAccordionIcon">
              <ContactInfo contactId={props.contact.id} />
            </li>
            <li className="design-modify-inbox-module-right-side collaborat__list common__list inboxAccordionIcon">
              {/* <AssignToStage
                contactStages={data.contactStages}
                contact_id={props.contact ? props.contact.id : null}
                refreshData={loadContactData}
                reloadTopSection={() => {}}
                user={data.loggedOnUser ? data.loggedOnUser.id : null}
                loggedOnUser={data.loggedOnUser ? data.loggedOnUser : null}
                people={props.contact ? props.contact : null}
                loadDataOnCommunication={() => {}}
              /> */}
              <AssignToStageV2
                contactStages={data.contactStages}
                contact_id={props.contact ? props.contact.id : null}
                refreshData={loadContactData}
                reloadTopSection={() => { }}
                user={data.loggedOnUser ? data.loggedOnUser.id : null}
                loggedOnUser={data.loggedOnUser ? data.loggedOnUser : null}
                people={props.contact ? props.contact : null}
                loadDataOnCommunication={() => { }}
              />
            </li>
            <li className="design-modify-inbox-module-right-side collaborat__list common__list inboxAccordionIcon">
              <TaskList
                people={props.contact ?? null}
                contact_id={props.contact ? props.contact.id : null}
                refreshData={() =>
                  props.fetchTasks({
                    contact_id: props.contactId,
                  })
                }
                user={data.loggedOnUser ? data.loggedOnUser : null}
                loggedOnUser={data.loggedOnUser ? data.loggedOnUser : null}
                activity={props.allActivityList}
                from={"Task"}
              />
            </li>
            <li className="design-modify-inbox-module-right-side collaborat__list common__list inboxAccordionIcon">
              <TaskList
                people={props.contact ?? null}
                loggedOnUser={data.loggedOnUser ? data.loggedOnUser : null}
                contact_id={props.contact ? props.contact.id : null}
                refreshData={() =>
                  props.fetchTasks({
                    contact_id: props.contactId,
                  })
                }
                user={data.loggedOnUser ? data.loggedOnUser : null}
                activity={props.allActivityList}
                from={"Appointment"}
              />
            </li>
            <li className="design-modify-inbox-module-right-side collaborat__list common__list inboxAccordionIcon">
              <Collaborators
                refreshCollaborator={() => { }}
                people={props.contact ?? null}
                contact_id={props.contact ? props.contact.id : null}
                refreshData={props.refreshData}
                className="light-blue-10"
                user={data.loggedOnUser ? data.loggedOnUser.id : null}
                refreshValue={props.refreshValue}
              />
            </li>

              <li className="design-modify-inbox-module-right-side collaborat__list common__list inboxAccordionIcon">
                <DNC />
              </li>

            <li className="design-modify-inbox-module-right-side collaborat__list common__list v2_single__contact_campaigns inboxAccordionIcon">
              <CampaignList
                people={props.contact ?? null}
                contact_id={props.contact ? props.contact.id : null}
                refreshData={props.refreshData}
                user={data.loggedOnUser ? data.loggedOnUser.id : null}
                showNotificationStart={showNotificationStart}
              />
            </li>

            <li className="design-modify-inbox-module-right-side collaborat__list common__list inboxAccordionIcon">
              <FormEntryList
                smartFormEntries={formEntries}
                entries={props.contact.form_entries ?? null}
              />
            </li>

            {/* <li className="design-modify-inbox-module-right-side collaborat__list common__list">
              <Tags
                refreshTag={() => {}}
                people={props.contact ?? null}
                contact_id={props.contact ? props.contact.id : null}
                refreshData={props.refreshData}
                className="light-blue-10"
                user={data.loggedOnUser ? data.loggedOnUser.id : null}
              />
            </li> */}

            <li className="design-modify-inbox-module-right-side collaborat__list common__list v2_single__contact_campaigns inboxAccordionIcon">
              <ContactNotes
                people={props.contact ?? null}
                contact_id={props.contact ? props.contact.id : null}
                refreshData={props.refreshData}
                user={data.loggedOnUser ? data.loggedOnUser.id : null}
                showNotificationStart={showNotificationStart}
                refreshValue={props.refreshValue}
              />
            </li>

            <li className="design-modify-inbox-module-right-side collaborat__list lead__source___inbox_right_side inboxAccordionIcon">
              <Source
                reloadTopSection={() => { }}
                sourceList={
                  props.sourceList !== undefined && props.sourceList != null
                    ? props.sourceList
                    : null
                }
              />
            </li>
            <li className="design-modify-inbox-module-right-side collaborat__list lead__source___inbox_right_side inboxAccordionIcon">
              <Unsubscribed contact_id={props.contact.id} />
            </li>
          </ul>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
    allActivityList: state.rightsideReducer.allActivityList,
    selectedContact: state.inboxReducer.selectedContact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTasks: (params, callBack) => dispatch(fetchTasks(params, callBack)),
    setContactDetails: (params) => dispatch(setContactDetails(params)),
    toggleRightSidebar: () => dispatch(toggleRightSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Miscellaneous);

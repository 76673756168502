import React from "react";
import { InputBase, MenuItem, Select, makeStyles, withStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        "&:hover": {
            backgroundColor: "#cbd4ff",
            color: "#133159",
        },
        "&.Mui-selected": {
            backgroundColor: "#cbd4ff",
            color: "#133159",
        },
    },
    menuItemLineThrough: {
        "&.MuiListItem-root": {
            textDecoration: "line-through",
            color: "#ee1b54",
        },
    },
    deleted: {
        fontSize: "9px"
    }
}));

const BasicInputSelect = withStyles((theme) => ({
    root: {
        
    },
    input: {
        backgroundColor: "#edf0fd !important",
        position: "relative",
        margin: "0 !important",
        padding: "10px !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        fontSize: "14px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
            backgroundColor: "#edf0fd !important"
        },
        "&::placeholder": {
            color: "#edf0fd !important",
        },
    },
}))(InputBase);

const BasicSelectForLeadSourceDropDown = ({ options, mapping = { label: "title", value: "id" }, defaultText = "Select", name = "default", onChange, value = "", disabled = false, selectedOption, deletedMapping, className = null, emptyable=false, fullWidth=true }) => {
    const classes = useStyles();
    const placeholderColor = "rgb(8 40 82 / 44%)";
    const activeColor = "#36454F";
    const handleSelectChange = (event) => {
        onChange(event);

        if (selectedOption) {
            const selected = options.find((option) => (option[mapping.value] === event.target.value));
            if (selected) {
                selectedOption(selected);
            }
        }
    };

    const menuItems = () => {
        return options.map((option, index) => {
            if (mapping) {
                if (deletedMapping && option[deletedMapping.field] === deletedMapping.value) {
                    if (value === option[mapping.value]) {
                        return (
                            <MenuItem 
                                disabled={(deletedMapping && option[deletedMapping.field] === deletedMapping.value)}
                                className={`${classes.menuItem} dropdownhelper-menuitem-class ${(deletedMapping && option[deletedMapping.field] === deletedMapping.value) ? classes.menuItemLineThrough : ""}`}
                                key={index}
                                value={option[mapping.value]}
                            >
                                {option[mapping.label]}
                                {(deletedMapping && option[deletedMapping.field] === deletedMapping.value) && (<span className={classes.deleted}>(deleted)</span>)}
                            </MenuItem>
                        )
                    } else {
                        return null;
                    }
                } else {
                    return (
                        <MenuItem 
                            className={`${classes.menuItem} dropdownhelper-menuitem-class`}
                            key={index}
                            value={option[mapping.value]}
                        >
                            {option[mapping.label]}
                        </MenuItem>
                    )
                }
            } else {
                return (
                    <MenuItem 
                        className={`${classes.menuItem} dropdownhelper-menuitem-class`}
                        key={index}
                        value={option}
                    >
                        {option}
                    </MenuItem>
                )
            }
        });
    };

    const selectWrapperStyles = makeStyles((theme) => ({
        wrapper: {
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        select: {
            whiteSpace: 'nowrap',
        },
    }));

    const SelectWrapper = React.forwardRef((props, ref) => {
        const classes = selectWrapperStyles();
        return (
            <div className={classes.wrapper}>
                <Select
                    fullWidth={fullWidth}
                    defaultValue={value}
                    value={value}
                    onChange={handleSelectChange}
                    name={name}
                    displayEmpty
                    disabled={disabled}
                    input={<BasicInputSelect
                        style={{ color: `${!value ? placeholderColor : activeColor}`}}
                        selectedOption={selectedOption}
                    />}
                    inputProps={{ "aria-label": "Without label" }}
                    ref={ref}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        PaperProps: {
                            style: {
                                maxHeight: "280px"
                            },
                        },
                        getContentAnchorEl: null,
                    }}
                    className={className ?? ''}
                >
                    <MenuItem 
                        className={`${classes.menuItem} dropdownhelper-menuitem-class`}
                        value=""
                        disabled={!emptyable}
                    >
                        {defaultText}
                    </MenuItem>
                    {menuItems()}
                </Select>
            </div>
        );
    });

    return (
        <SelectWrapper />
    );
};

export default BasicSelectForLeadSourceDropDown;
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Close } from "@material-ui/icons";
import { toggleRightSidebar } from "../../../actions/inboxAction";
import { fetchTasks, setContactDetails } from "./redux/contactAction";
import {
  fetchContactDetails,
  getContactSmartFormEntries,
} from "../../../api/contactApi";
import CampaignList from "./campaignList/CampaignList";
import Collaborators from "./collaborator/Collaborators";
import "./Contact.css";
import "./right-side-design-modify.css";
import "./ContactResponsive.css";
import "./contact-list.css";
import AssignToStage from "./deal/AssignToStage";
import NewLoader from "./Helpers/NewLoader";
import LeadOwner from "./LeadOwner";
import TaskList from "./task/TaskList";
import Source from "./Source";
import Avatar from "@material-ui/core/Avatar";
import Utils from "../../../helpers/Utils";
import Helper from "./Helpers/Helper";
import ContactNotes from "./notes";
import FormEntryList from "./formEntryList/FormEntryList";
import "./right-side-alt.css";
import { HistoryProvider } from "../../../App";
import Tags from "./Tag/Tags";
import CarrierLookup from "../../carrierLookup";
import dncNoCall from "../../common/dncNoCall";
import DNC from "./dnc/DNC";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { EnhancedDataButtonBackground } from "../icons";

const padZero = (value) => {
  return value < 10 ? `0${value}` : value;
};

const RightSide = (props) => {
  const history = useContext(HistoryProvider);
  const [formEntries, setFormEntries] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);
  const [data, setData] = useState(null);
  const [contactVerificationStats, setContactVerificationStats] =
    useState(null);
  const [carrierLookupPrices, setCarrierLookupPrices] = useState(null);

  useEffect(() => {
    setComponentLoading(true);
    if (props.data != null) {
      setComponentLoading(false);
      setData(props.data);
    } else {
      loadContactData();
    }
    getContactSmartFormEntries(props.contactId)
      .then((res) => {
        if (res && res.success) {
          setFormEntries(res.data);
        } else {
          setFormEntries([]);
        }
      })
      .catch((err) => {
        setFormEntries([]);
      });
  }, [props.contactId]);

  // useEffect(()=> {
  //     if(props.from !== undefined && props.from !== null && props.from ==='inbox'){
  //         loadData()
  //     }else{
  //         setData(props.data)
  //     }
  // },[]);

  // useEffect(()=> {
  //     props.fetchTasks({
  //         contact_id : props.contactId
  //     });
  // },[props.contactId])

  const loadContactData = async () => {
    let param = { id: props.contactId };
    if (Utils.getAccountData("carrierLookup")) {
      param["carrierLookup"] = true;
    }
    fetchContactDetails(param)
      .then((response) => {
        if (response.data.status === "success") {
          setData(response.data.data);
          props.setContactDetails(response.data.data["people"]);
        } else {
          window.showNotification("ERROR", response.data.message);
        }
      })
      .finally(() => {
        setComponentLoading(false);
      });
  };

  const showNotificationStart = (type, message) => {
    window.showNotification(type, message);
  };

  const renderName = (firstName, lastName) => {
    let message = "";
    if (!Utils.isEmpty(firstName) && !Utils.isEmpty(lastName)) {
      message = firstName + " " + lastName;
    } else if (!Utils.isEmpty(firstName)) {
      message = firstName;
    } else if (!Utils.isEmpty(lastName)) {
      message = lastName;
    }

    return Helper.limitMessage(message, 28);
  };

  return (
    <React.Fragment>
      <div className="col s12 m12 l4 xl3 lead_owner_collapsible-old lead_owner_collapsible-design-modify white inbox-right-side v2 v2_lead_owner_collapsible inbox-right-side-height-alt">
        <div
          className="closeRightSidebar waves-effect"
          onClick={(e) => {
            e.preventDefault();
            props.toggleRightSidebar();
          }}
        >
          <Close />
        </div>
        {componentLoading || data == null || props.contact == null ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NewLoader
              loading={true}
              onlyLoader={true}
              size={25}
              message={""}
            />
          </div>
        ) : (
          <React.Fragment>
            <div className="mt-4 user-right-card-wrapper">
              <div className="text-center user-right-card position-relative">
                {(Utils.getAccountData("carrierLookup") ||
                  Utils.getAccountData("dnc") ||
                  Utils.getAccountData("skipTrace")) && (
                  <BootstrapTooltip
                    arrow
                    title="Click here for data enhancement"
                  >
                    <span
                      className="enhanceDataButton"
                      onClick={() => {
                        window.globalEnhancedData(true, {
                          id: props.contact.id,
                          email: props.contact.email,
                          number: props.contact.number,
                          address: props.contact.address,
                          city: props.contact.city,
                          state: props.contact.state,
                          zip: props.contact.zip,
                          contact_additional_informations:
                            props.contact.contact_additional_informations,
                          requestModule: "CONTACT",
                          callback: (res) => {
                            if (res) {
                              let tempContact = { ...props.contact };

                              const [isValid, inValid, doNotCall, verifiedOk] =
                                [
                                  "VALID",
                                  "INVALID",
                                  "DO_NOT_CALL",
                                  "VERIFIED_OK",
                                ];
                              const [requestDone, requestFailed] = [
                                "REQUEST_DONE",
                                "REQUEST_FAILED",
                              ];

                              let cleanDataInfos = null;

                              if (res.data && Array.isArray(res.data)) {
                                cleanDataInfos = res.data.find(
                                  (validationInfo) =>
                                    validationInfo.source === "CONTACT"
                                );
                              }

                              const currentDate = new Date();
                              const formattedDate = `${currentDate.getFullYear()}-${padZero(
                                currentDate.getMonth() + 1
                              )}-${padZero(currentDate.getDate())} ${padZero(
                                currentDate.getHours()
                              )}:${padZero(currentDate.getMinutes())}:${padZero(
                                currentDate.getSeconds()
                              )}`;

                              if (cleanDataInfos) {
                                if (
                                  !tempContact.contact_additional_informations
                                ) {
                                  tempContact.contact_additional_informations =
                                    {};
                                }

                                if (
                                  res["email-lookup-status"] === requestDone &&
                                  cleanDataInfos["email-validation-status"]
                                ) {
                                  tempContact.contact_additional_informations.email_validation_status =
                                    cleanDataInfos["email-validation-status"];
                                  tempContact.contact_additional_informations.email_validation_infos =
                                    { state: cleanDataInfos["email-state"] };
                                  tempContact.contact_additional_informations.email_validation_date =
                                    formattedDate;
                                }

                                if (
                                  res["carrier-lookup-status"] ===
                                    requestDone &&
                                  cleanDataInfos["number-validation-status"]
                                ) {
                                  tempContact.contact_additional_informations.number_validation_status =
                                    cleanDataInfos["number-validation-status"];
                                  tempContact.contact_additional_informations.number_validation_infos =
                                    { state: cleanDataInfos["number-state"] };
                                  tempContact.contact_additional_informations.number_validation_date =
                                    formattedDate;
                                }

                                if (
                                  res["dnc-lookup-status"] === requestDone &&
                                  cleanDataInfos["dnc-state"]
                                ) {
                                  tempContact.contact_additional_informations.dnc_status =
                                    cleanDataInfos["dnc-state"];
                                  tempContact.contact_additional_informations.dnc_validation_date =
                                    formattedDate;
                                }

                                if (
                                  res["skip-trace-lookup-status"] ===
                                  requestDone
                                ) {
                                  tempContact.contact_additional_informations.skip_trace_date =
                                    formattedDate;
                                }
                              }

                              props.setContactDetails(tempContact);
                            }
                          },
                        });
                      }}
                    >
                      <EnhancedDataButtonBackground />
                    </span>
                  </BootstrapTooltip>
                )}
                <Link to={`/contacts/${props.contactId}`} target="_blank">
                  <div className="user-image profile_img cursor-pointer">
                    <Avatar style={{ margin: "0 auto" }}>
                      {props.contact.first_name === undefined
                        ? "NA"
                        : Utils.getInitial(
                            (props.contact.first_name
                              ? props.contact.first_name
                              : "") +
                              " " +
                              (props.contact.last_name
                                ? props.contact.last_name
                                : "")
                          ).toUpperCase()}
                    </Avatar>
                  </div>
                </Link>
                <div className="user_profile_name d-flex justify-content-center align-items-center">
                  {/*<input type="text" value={ props.contact.first_name + ' ' + props.contact.first_name }/>*/}
                  <a
                    href={`/contacts/${props.contact.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/contacts/${props.contact.id}`);
                    }}
                  >
                    <h5>
                      {renderName(
                        props.contact.first_name,
                        props.contact.last_name
                      )}
                    </h5>
                  </a>
                </div>
                <div className="user_designations">
                  <p>{Helper.limitMessage(props.contact.company_name, 27)}</p>
                </div>
                <div className="contact_info">
                  {props.contact.email && (
                    <div className="d-flex align-items-center justify-content-center">
                      {Utils.getAccountData("carrierLookup") &&
                      props.contact &&
                      props.contact.email &&
                      props.contact.email.trim() !== "" ? (
                        <CarrierLookup validationType={"EMAIL"} />
                      ) : (
                        <span className="email_text person_info">Email: </span>
                      )}
                      <p className="email truncate ml-2">
                        {Helper.limitMessage(props.contact.email, 25)}
                      </p>
                    </div>
                  )}
                  {props.contact.number && (
                    <div className="d-flex align-items-center justify-content-center">
                      {props.contact &&
                      props.contact.number &&
                      props.contact.number.trim() !== "" ? (
                        <div
                          className="d-flex align-content-center"
                          style={{ gap: "10px" }}
                        >
                          {Utils.getAccountData("carrierLookup") && (
                            <CarrierLookup validationType={"NUMBER"} />
                          )}

                          {Utils.getAccountData("dnc") && (
                            <CarrierLookup validationType={"DNC_STATUS"} />
                          )}
                        </div>
                      ) : (
                        <span className="phn_no_text person_info">
                          Phone Number:
                        </span>
                      )}
                      <span className="ml-2">
                        <p className="phone truncate">
                          {props.contact.number}
                          <a
                            href="#!"
                            className="call__button"
                            onClick={(e) => {
                              e.preventDefault();
                              Utils.getAccountData("dnc") &&
                              props.contact &&
                              props.contact.number
                                ? props.contact
                                    .contact_additional_informations &&
                                  props.contact.contact_additional_informations
                                    .dnc_status === "DO_NOT_CALL"
                                  ? dncNoCall({
                                      takeRisk: () => {
                                        window.initiateGlobalDialer(
                                          props.contact
                                        );
                                      },
                                      cancelButtonText: "Don't Call",
                                    })
                                  : window.initiateGlobalDialer(props.contact)
                                : window.initiateGlobalDialer(props.contact);
                            }}
                          >
                            <i className="material-icons">call</i>
                          </a>
                        </p>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <LeadOwner
              reloadTopSection={() => {}}
              // user_id={data.owner ? data.owner.id : null}
              // loggedOnUser={data.loggedOnUser ? data.loggedOnUser.id : null}
              data={data}
            />
            <ul className="collapsible awesome__scroll_bar">
              {Utils.getAccountData("dnc") &&
                props.contact &&
                props.contact.number &&
                props.contact.number.trim() !== "" && (
                  <li className="design-modify-inbox-module-right-side collaborat__list common__list">
                    <DNC />
                  </li>
                )}
              <li className="design-modify-inbox-module-right-side collaborat__list common__list">
                <Collaborators
                  refreshCollaborator={() => {}}
                  people={props.contact ?? null}
                  contact_id={props.contact ? props.contact.id : null}
                  refreshData={props.refreshData}
                  className="light-blue-10"
                  user={data.loggedOnUser ? data.loggedOnUser.id : null}
                  refreshValue={props.refreshValue}
                />
              </li>
              <li className="design-modify-inbox-module-right-side collaborat__list common__list">
                <Tags
                  refreshTag={() => {}}
                  people={props.contact ?? null}
                  contact_id={props.contact ? props.contact.id : null}
                  refreshData={props.refreshData}
                  className="light-blue-10"
                  user={data.loggedOnUser ? data.loggedOnUser.id : null}
                />
              </li>
              <li className="design-modify-inbox-module-right-side collaborat__list common__list">
                <AssignToStage
                  contactStages={data.contactStages}
                  contact_id={props.contact ? props.contact.id : null}
                  refreshData={loadContactData}
                  reloadTopSection={() => {}}
                  user={data.loggedOnUser ? data.loggedOnUser.id : null}
                  loggedOnUser={data.loggedOnUser ? data.loggedOnUser : null}
                  people={props.contact ? props.contact : null}
                  loadDataOnCommunication={() => {}}
                />
              </li>
              <li className="design-modify-inbox-module-right-side collaborat__list common__list">
                <TaskList
                  people={props.contact ?? null}
                  contact_id={props.contact ? props.contact.id : null}
                  refreshData={() =>
                    props.fetchTasks({
                      contact_id: props.contactId,
                    })
                  }
                  user={data.loggedOnUser ? data.loggedOnUser : null}
                  loggedOnUser={data.loggedOnUser ? data.loggedOnUser : null}
                  activity={props.allActivityList}
                  from={"Task"}
                />
              </li>
              <li className="design-modify-inbox-module-right-side collaborat__list common__list">
                <TaskList
                  people={props.contact ?? null}
                  loggedOnUser={data.loggedOnUser ? data.loggedOnUser : null}
                  contact_id={props.contact ? props.contact.id : null}
                  refreshData={() =>
                    props.fetchTasks({
                      contact_id: props.contactId,
                    })
                  }
                  user={data.loggedOnUser ? data.loggedOnUser : null}
                  activity={props.allActivityList}
                  from={"Appointment"}
                />
              </li>
              <li className="design-modify-inbox-module-right-side collaborat__list common__list v2_single__contact_campaigns">
                <CampaignList
                  people={props.contact ?? null}
                  contact_id={props.contact ? props.contact.id : null}
                  refreshData={props.refreshData}
                  user={data.loggedOnUser ? data.loggedOnUser.id : null}
                  showNotificationStart={showNotificationStart}
                />
              </li>
              <li className="design-modify-inbox-module-right-side collaborat__list common__list v2_single__contact_campaigns">
                <ContactNotes
                  people={props.contact ?? null}
                  contact_id={props.contact ? props.contact.id : null}
                  refreshData={props.refreshData}
                  user={data.loggedOnUser ? data.loggedOnUser.id : null}
                  showNotificationStart={showNotificationStart}
                  refreshValue={props.refreshValue}
                />
              </li>
              <li className="design-modify-inbox-module-right-side collaborat__list common__list">
                <FormEntryList
                  smartFormEntries={formEntries}
                  entries={props.contact.form_entries ?? null}
                />
              </li>
              <li className="design-modify-inbox-module-right-side collaborat__list lead__source___inbox_right_side">
                <Source
                  reloadTopSection={() => {}}
                  // sourceId={props.contact.source}
                  // contact_id={props.contact.id}
                  sourceList={
                    props.sourceList !== undefined && props.sourceList != null
                      ? props.sourceList
                      : null
                  }
                />
              </li>
            </ul>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
    allActivityList: state.rightsideReducer.allActivityList,
    selectedContact: state.inboxReducer.selectedContact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTasks: (params, callBack) => dispatch(fetchTasks(params, callBack)),
    setContactDetails: (params) => dispatch(setContactDetails(params)),
    toggleRightSidebar: () => dispatch(toggleRightSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightSide);

import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import StarIcon from '@material-ui/icons/Star'
import {
  createAdditionalContactApi,
  deleteAdditionalContactEmailApi,
  makeDefaultAdditionalContactEmailApi,
  updateAdditionalContactApi,
  updateContactDetailsV2,
  deleteContactEmailApi,
} from '../../../../api/contactApi'
import Utils from '../../../../helpers/Utils'
import NewConfirmAlert from '../../../common/new-alert/NewConfirmAlert'
import BootstrapTooltip from '../../../globals/BootstrapTooltip'
import { BasicInput, RightSideV2Styles } from '../RightSideV2Styles'
import CustomModal from '../common/CustomModal/CustomModal'
import PrimaryRoundedButton from '../common/button/PrimaryRoundedButton'
import { connect } from 'react-redux'
import {
  fetchContactAdditionalContacts,
  updateMultipleData,
} from '../../../../actions/inboxAction'
import { setContactDetails } from '../../rightSide/redux/contactAction'
import CarrierLookup from '../common/carrierLookup/CarrierLookup'
import { LOOKUP_TYPE_EMAIL_LOOKUP } from '../../../../constants/CoreConstants'
import { LOOKUP_STATUS } from '../common/carrierLookup/Constants'
import EmailIcon from '@material-ui/icons/Email'
import {isDeleteAble} from "../helper/utils";

const useStyles = makeStyles({
  dialogContentWidth: {
    width: '605px',
  },
  errorMessage: {
    position: "absolute",
    bottom: "-12px",
  },
})

const EditEmailModal = ({
  open,
  onClose,
  contact,
  contactAdditionalContacts,
  fetchContactAdditionalContacts,
  updateMultipleData,
  setContactDetails,
}) => {
  const { closeButton, flexColumn, secondaryButton, titleIcon, flexCenter } =
    RightSideV2Styles()
  const [emails, setEmails] = useState([])

  useEffect(() => {
    let tempEmails = [];
    let alreadySavedEmails = [];

    if (contact.email) {
      tempEmails.push({
        isDefault: true,
        email: contact.email,
        edited: false,
        backup: contact.email,
        error: '',
      });
      alreadySavedEmails.push(contact.email);
    }
    let compareEmail = {};
    if (contactAdditionalContacts && contactAdditionalContacts[0]) {
      for (let i = 0; i < contactAdditionalContacts.length; i++) {
        if (contactAdditionalContacts[i].email) {
          tempEmails.push({
            isDefault: false,
            email: contactAdditionalContacts[i].email,
            backup: contactAdditionalContacts[i].email,
            id: contactAdditionalContacts[i].id,
            ogIndex: i,
            edited: false,
            error: '',
            email_validation_infos:
              contactAdditionalContacts[i].email_validation_infos,
            email_validation_status:
              contactAdditionalContacts[i].email_validation_status,
          });
          compareEmail[contactAdditionalContacts[i].email] = true;
        }
      }
    }
    if (emails[0]) {
      emails.forEach((item) => {

        if (!compareEmail[item.email]) {
          if (!alreadySavedEmails.includes(item.email)) {
            tempEmails.push(item);
          }
        }
      })
    }

    setEmails(tempEmails)
  }, [contact, contactAdditionalContacts])

  const onChangeField = (field, value, index) => {
    setEmails((prevEmails) => {
      const data = [...prevEmails]

      if (field === 'isDefault' && (!data[1] || value)) {
        data.forEach((item) => (item.isDefault = false))
      }

      data[index][field] = value
      data[index].edited = data[index].backup !== value
      data[index].error = ''

      return data
    })
  }

  const onDeleteEmail = (email, index) => {
    if (!contact.email) {
      let data = [...emails];
      data.splice(index, 1);

      setEmails(data);
      return;
    }

    NewConfirmAlert({
      onSubmit: async () => {
        let response = await deleteContactEmailApi({
          contactId: contact.id,
        });

        if (response.success) {
          let data = [...emails];
          data.splice(index, 1);

          setEmails(data);
          let tmpContact = { ...contact };

          tmpContact.email = "";
          setContactDetails(tmpContact);
        }
      },
      title: "Confirm",
      description: "Are you sure to delete this email?",
      cancelText: "No",
      submitText: "Yes",
      width: "480px",
    });
  }

  const onDeleteAdditionalEmail = (email, index) => {
    if (!email.id) {
      let data = [...emails]
      data.splice(index, 1)

      setEmails(data)
      return
    }

    NewConfirmAlert({
      onSubmit: async () => {
        let response = await deleteAdditionalContactEmailApi({
          additionalContactId: email.id,
        })

        if (response.success) {
          let data = [...emails]
          data.splice(index, 1)

          setEmails(data)
          let tempAddi = [...contactAdditionalContacts]
          if (tempAddi[email.ogIndex]['number']) {
            tempAddi[email.ogIndex]['email'] = null
          } else {
            tempAddi.splice(email.ogIndex, 1)
          }
          updateMultipleData({ contactAdditionalContacts: tempAddi })
        }
      },
      title: 'Confirm',
      description: 'Are you sure to delete this email?',
      cancelText: 'No',
      submitText: 'Yes',
      width: '480px',
    })
  }

  const appendEmail = () => {
    let object = {
      isDefault: false,
      email: '',
      edited: false,
    }
    let data = [...emails, object]

    setEmails(data)
  }

  const onCloseEdit = (index) => {
    setEmails((prevEmails) => {
      const data = [...prevEmails]

      data[index].email = data[index].backup
      data[index].edited = false
      data[index].error = ''

      return data
    })
  }

  const makeDefault = (item, index) => {
    NewConfirmAlert({
      onSubmit: async () => {
        let response = await makeDefaultAdditionalContactEmailApi({
          additionalContactId: item.id,
        })

        if (response.success) {
          onChangeField('isDefault', true, index)
          defaultProcess(item, index)
          fetchContactAdditionalContacts({
            page_no: 1,
            per_page: 100,
            contact_id: contact.id,
          })
        }
      },
      title: 'Confirm',
      description: 'Would you like to make this the primary email for the contact?',
      cancelText: 'No',
      submitText: 'Yes',
      width: '480px',
    })
  }

  const onSaveDefaultEmail = async (email, index) => {
    let response = await updateContactDetailsV2({
      contactId: contact.id,
      params: {
        email: email.email,
      },
    })
    if (response.success) {
      resetProcess({ email: email.email, isDefault: true })
      fetchContactAdditionalContacts({
        page_no: 1,
        per_page: 100,
        contact_id: contact.id,
      })
    } else if (!response.success && response.status === 403) {
      setEmails((prevEmails) => {
        const newData = [...prevEmails]
        if (response.data && response.data.data && response.data.data.email) {
          newData[index].error = response.data.data.email[0]
        }
        return newData
      })
    }
  }

  const onCreateAdditionalEmail = async (email, index) => {
    let response = await createAdditionalContactApi({
      contact_id: contact.id,
      email: email.email,
      first_name: '---',
      last_name: '---',
      contact_relation: 'OTHERS',
      label: '---',
    })

    if (response && response.data) {
      let data = response.data
      if (data && data.status === 'success') {
        let tempAddi = [...contactAdditionalContacts]
        tempAddi.push({
          id: data.data.id,
          contact_id: contact.id,
          email: email.email,
          first_name: '---',
          last_name: '---',
          contact_relation: 'OTHERS',
          label: '---',
        })
        updateMultipleData({ contactAdditionalContacts: tempAddi })
      } else if (data && data.status === 'validation-error') {
        setEmails((prevEmails) => {
          const newData = [...prevEmails]
          if (data.html && data.html.email) {
            newData[index].error = data.html.email[0]
          }
          return newData
        })
      }
    }
  }

  const onUpdateAdditionalEmail = async (email, index) => {
    let response = await updateAdditionalContactApi({
      id: email.id,
      contact_id: contact.id,
      email: email.email,
      first_name: '---',
      last_name: '---',
      contact_relation: 'OTHERS',
      label: '---',
    })

    if (response && response.data) {
      let data = response.data
      if (data && data.status === 'success') {
        let tempAddi = [...contactAdditionalContacts]
        tempAddi[email.ogIndex].email = email.email
        tempAddi[email.ogIndex].email_validation_infos = null
        tempAddi[email.ogIndex].email_validation_status =
          LOOKUP_STATUS.NOT_VERIFIED
        updateMultipleData({ contactAdditionalContacts: tempAddi })
      } else if (data && data.status === 'validation-error') {
        setEmails((prevEmails) => {
          const newData = [...prevEmails]
          if (data.html && data.html.email) {
            newData[index].error = data.html.email[0]
          }
          return newData
        })
      }
    }
  }

  const handleSave = async (email, index) => {
    if (
      email.email !== undefined &&
      email.email !== null &&
      email.email.trim() === ''
    ) {
      setEmails((prevEmails) => {
        const data = [...prevEmails]
        data[index].error = 'Email is required!'
        return data
      })
      return
    }

    if (!Utils.validateEmail(email.email)) {
      setEmails((prevEmails) => {
        const data = [...prevEmails]
        data[index].error = 'Invalid email!'
        return data
      })
      return
    }

    if (email.isDefault || !contact.email) {
      await onSaveDefaultEmail(email, index)
    } else {
      if (email.id) {
        await onUpdateAdditionalEmail(email, index)
      } else {
        await onCreateAdditionalEmail(email, index)
      }
    }
  }

  const defaultProcess = (item) => {
    let tmpContact = { ...contact }
    if (item.email) {
      tmpContact.email = item.email

      if (!tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations = {}
      }

      if (tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations.email_validation_status =
          item.email_validation_status || 'NOT_VERIFIED'
        tmpContact.contact_additional_informations.email_validation_infos =
          item.email_validation_infos || null
      }
    }
    setContactDetails(tmpContact)
  }

  const resetProcess = (payload = null) => {
    let tmpContact = { ...contact }

    if (payload && payload.email) {
      tmpContact.email = payload.email
    }

    if (payload && payload.isDefault) {
      if (!tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations = {}
      }

      if (tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations.email_validation_status =
          'NOT_VERIFIED'
        tmpContact.contact_additional_informations.email_validation_infos = null
      }
    }
    setContactDetails(tmpContact)
  }
  const classes = useStyles()
  return (
    <div>
       <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth={1200}
    >
      <CustomModal
        icon={<EmailIcon />}
        title={'Update Email'}
        open={open}
        onClose={onClose}
      />
      <DialogContent className={classes.dialogContentWidth}>
        <DialogContentText id='alert-dialog-description'>
          {emails[0] && (
            <Box
              className={flexCenter}
              sx={{ justifyContent: 'flex-end', mt: 2 }}
            >
              <PrimaryRoundedButton
                startIcon={<AddIcon />}
                onClick={appendEmail}
              >
                Add New
              </PrimaryRoundedButton>
            </Box>
          )}
          <Box className={flexColumn} sx={{ gap: 16 }} py={3}>
            {emails[0] ? (
              emails.map((email, index) => (
                <Grid container spacing={1} className={flexCenter} style={{ position: 'relative' }}>
                  <Grid item md={8}>
                    <BasicInput
                      fullWidth
                      placeholder='Enter Email'
                      value={email.email}
                      onChange={(event) =>
                        onChangeField('email', event.target.value, index)
                      }
                    />
                    {email.error && (
                      <FormHelperText className={classes.errorMessage}>{email.error}</FormHelperText>
                    )}
                  </Grid>
                  {email.edited && (
                    <Grid item md={2} className={flexCenter}>
                      <Button
                        className={secondaryButton}
                        variant='contained'
                        color='secondary'
                        size='small'
                        disableElevation
                        style={{
                          borderRadius: 25,
                          paddingBlock: 1,
                          marginLeft: 5,
                        }}
                        onClick={handleSave.bind(this, email, index)}
                      >
                        Save
                      </Button>
                      <Button
                        className={closeButton}
                        variant='contained'
                        color='secondary'
                        size='small'
                        disableElevation
                        style={{
                          borderRadius: 25,
                          paddingBlock: 1,
                          marginLeft: 5,
                        }}
                        onClick={onCloseEdit.bind(this, index)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  )}
                  {!email.edited &&
                    Utils.getAccountData('carrierLookup') &&
                    email.email && (
                      <Grid item>
                        <Box px={0.5}>
                          <CarrierLookup
                            lookupType={LOOKUP_TYPE_EMAIL_LOOKUP}
                            validator={true}
                            additional={email.isDefault ? null : email}
                          />
                        </Box>
                      </Grid>
                    )}
                  {!email.edited && (email.id || email.isDefault) && (
                    <Grid item>
                      <BootstrapTooltip
                        arrow
                        title={email.isDefault ? 'Primary' : 'Secondary'}
                      >
                        <IconButton
                          size='small'
                          className={titleIcon}
                          onClick={() => {
                            if (!email.isDefault && email.email) {
                              makeDefault(email, index)
                            }
                          }}
                        >
                          {email.isDefault ? (
                            <StarIcon
                              style={{ color: '#faaf00' }}
                              fontSize='small'
                            />
                          ) : (
                            <StarIcon color='action' fontSize='small' />
                          )}
                        </IconButton>
                      </BootstrapTooltip>
                    </Grid>
                  )}
                  {!email.edited && (email.isDefault ? isDeleteAble(contact, "email") : true) && (
                    <Grid item>
                      <IconButton
                        size='small'
                        className={titleIcon}
                        onClick={() => {
                          if (email.isDefault && !isDeleteAble(contact, "email")){
                            window.showNotification("error", "No default number available for this contact.");
                            return;
                          }
                          if (email.isDefault) {
                            onDeleteEmail(index);
                          }else {
                            onDeleteAdditionalEmail(email, index);
                          }
                        }}
                      >
                        <DeleteForeverIcon color='error' />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ))
            ) : (
              <Box
                className={flexCenter}
                sx={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  mt: 2,
                }}
              >
                <Typography
                  variant='body1'
                  color='textSecondary'
                  style={{ marginBottom: '15px' }}
                >
                  <i>Email Not Found</i>
                </Typography>
                <PrimaryRoundedButton
                  startIcon={<AddIcon />}
                  onClick={appendEmail}
                >
                  Add New
                </PrimaryRoundedButton>
              </Box>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
    </div>
   
   
  )
}

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
    contactAdditionalContacts: state.inboxReducer.contactAdditionalContacts,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchContactAdditionalContacts: (params) =>
      dispatch(fetchContactAdditionalContacts(params)),
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
    setContactDetails: (params) => dispatch(setContactDetails(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailModal)

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import BasicSelect from "./custom/BasicSelect";
import BasicInput from "./custom/BasicInput";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { ErrorMessage, ModalHead, ModalTitle } from "./PolicyStyle";
import {useDispatch, useSelector} from "react-redux";
import { addContactPolicy, getContactPolicyCategory } from "../../../../api/contactApi";
import { updateMultipleData } from "../../../../actions/inboxAction";


const useStyle = makeStyles({
  formLabel: {
    fontWeight: "600 !important",
    color: "#181f48",
    marginBottom: "0px !important",
  },

  addDealButton: {
    color: " white !important",
    padding: "7px 12px 8px !important",
    fontWeight: "600 !important",
    borderRadius: "5px !important",
    textTransform: "capitalize !important",
    backgroundColor: " #316AFF !important",
  },
  cancelButton: {
    color: " #316AFF !important",
    textTransform: "capitalize !important",
    border: "1px solid #316AFF",
  },
});

const AddNewPolicyTypeModal = ({ open, onClose,setSelectedPolicy }) => {
  const dispatch = useDispatch();
  const {contactPolicyCompanyAgentData} = useSelector((state)=>(state.rightsideReducer));
  const [policyType, setPolicyType] = useState(null);
  const [policyCategory, setPolicyCategory] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPolicyCategory, setSelectedPolicyCategory] = useState("");
  const [policyError,setPolicyError]=useState(false)
  useEffect(() => {
    fetchCategory();
  }, []);

  const handleSetPolicy=(e)=>{
    e.preventDefault()
    const { value } = e.target;
    setPolicyType(value)
    setPolicyError(false)
  }
  const classes = useStyle();
  const fetchCategory = async () => {
    try {
      const response = await getContactPolicyCategory();
      if (response && response.success && response?.data) {
        setPolicyCategory(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const handleSavePolicy = async () => {
    try {
      if(policyType?.length>50){
        return
      }
      if(!policyType){
        setPolicyError(true)
        return
      }
      setIsSubmitting(true)
      const payload = {
        title:policyType,
        userProductFolderId: selectedPolicyCategory,
      };
      const response = await addContactPolicy(payload);
      if (response && response.success && response?.data) {
        window.showNotification("SUCCESS", response.message);
        setSelectedPolicy(response.data.id);
        let oldData = {...contactPolicyCompanyAgentData};
        oldData.policyTypes.push({ label: response.data.title, value: response.data.id });
        dispatch(updateMultipleData({ contactPolicyCompanyAgentData: contactPolicyCompanyAgentData }));
        onClose();
      }
      else{
        window.showNotification("ERROR", response.message);
        onClose();
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <ModalHead>
          <ModalTitle>Add a New Policy Type</ModalTitle>
        </ModalHead>
        <Box minWidth={600} p={"16px"}>
          <Box>
            <Box>
              <Typography className={classes.formLabel}>Policy Type</Typography>
              <BasicInput
                type='text'
                onChange={handleSetPolicy}
                fullWidth
                placeholder='Enter Policy Type'
              />
              {
                policyType?.length>50 && <ErrorMessage>Policy Title can not be more than 50 character</ErrorMessage>
              }
              {
                policyError && <ErrorMessage>Policy title is required</ErrorMessage>
              }
            </Box>
            <Box mt={2}>
              <Typography className={classes.formLabel}>Policy Category</Typography>
              <BasicSelect
                fullWidth
                name='category'
                defaultText='Select an options'
                value={selectedPolicyCategory}
                options={policyCategory}
                onChange={(e) => setSelectedPolicyCategory(e.target.value)}
                mapping={{
                  label: "label",
                  value: "value",
                }}
              />
            </Box>

            <Box display={"flex"} justifyContent={"end"} gridGap={16} mt={4}>
              <Button className={classes.cancelButton} variant='outlined' onClick={() => onClose()}>
                Cancel
              </Button>
              <Button  variant='contained' disabled={isSubmitting} className={classes.addDealButton} onClick={handleSavePolicy}>
               {  isSubmitting?"  Saving...":"Save"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default AddNewPolicyTypeModal;

import React, { useEffect, useState } from "react"
import { Box } from "@material-ui/core"
import LeadType from "./LeadType"
import Styles from "./leadstatus.module.css"
import { connect } from "react-redux"
import { fetchTasks,setContactDetails } from "../../redux/contactAction"
import { toggleRightSidebar } from "../../../../../actions/inboxAction"
import LeadOwnerCopy from "./LeadOwnerCopy"

const LeadStatus = (props) => {
 const [componentLoading, setComponentLoading] = useState(true)

 useEffect(() => {
   setComponentLoading(true)
   if (props.data != null) {
     setComponentLoading(false)
   }

   setTimeout(() => {
     props.fetchTasks({
       contact_id: props.contactId,
     })
   }, 1000)
 }, [props.contactId])

  return (
    <Box className={Styles.leadstatus} sx={{ gap: "10px", my: 2 }}>
      
      
      <LeadOwnerCopy
        data={props.contact}
        contactId={props.contact?.id}
        reloadTopSection={() => {}}
        />
      <LeadType
        contactDetails={props.contactDetails}
        contactId={props.contact?.id}
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
    allActivityList: state.rightsideReducer.allActivityList,
    selectedContact: state.inboxReducer.selectedContact,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTasks: (params, callBack) => dispatch(fetchTasks(params, callBack)),
    setContactDetails: (params) => dispatch(setContactDetails(params)),
    toggleRightSidebar: () => dispatch(toggleRightSidebar()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadStatus)



import { useEffect, useState } from "react";
import { Grid } from '@material-ui/core';
import { Mention, MentionsInput } from 'react-mentions';
import defaultStyle from './defaultStyle';
import './note.css';
import LinkFile from "../notes/LinkFile";
import { addNoteRightSide } from "../../../../api/contactApi";
import { getAllTeamUsers, updateTimelineNote } from "../redux/contactApi";
import { Skeleton } from "@material-ui/lab";

const mentionStyle = {
    backgroundColor: '#cee4e5'
};

const NoteComponent = ({
    open,
    onClose,
    data,
    isEdit,
    successCallback,
    contactId
}) => {
    const [submitingNote, setSubmitingNote] = useState(false)
    const [note, setNote] = useState('')
    const [allTeamUser, setAllTeamUser] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        loadTeamUserList();
        if(isEdit){
            if(data !== null){
                setNote(data.message)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();
            if (submitingNote) return;
    
        if (note.trim() === "") {
            showNotification("WARNING", "Note description is required!");
            return;
        }
         setSubmitingNote(true);
    
        try {
            let response;
            
            if (!isEdit) {
                response = await addNoteRightSide({
                    contact_id: contactId,
                    origin: 3,
                    note: note
                });
    
                if (response?.data?.success) {
                    handleSuccess();
                } else {
                    throw new Error("Failed to add note");
                }
            } 
            else {
                response = await updateTimelineNote({
                    contact_id: contactId,
                    id: data.id,
                    note: note
                });
    
                if (response?.data?.status === "success") {
                    handleSuccess(note);
                } else {
                    throw new Error("Failed to update note");
                }
            }
        } catch (error) {
            showNotification("ERROR", error.message || "Something went wrong!");
        } finally {
            setSubmitingNote(false);
        }
    };
    
    const handleSuccess = (noteContent = '') => {
        window.showNotification("SUCCESS", "Note added successfully");
        successCallback(noteContent);
        window.refreshConersation();
        
        setTimeout(() => {
            onClose(true);
        }, 300); 
    };
    
    const showNotification = (type, message) => {
        if (window.showNotification) {
            window.showNotification(type, message);
        }
    };
    
    const buttonText = () => {
        if(isEdit && submitingNote){
            return 'Editing Note'
        }
        else if(isEdit && !submitingNote){
            return 'Edit Note'
        }
        else if(isEdit && submitingNote){
            return 'Saving Note'
        }
        else{
            return 'Save Note'
        }
    }
    const handleChangeNote = (event) => {
        setNote((event.target.value) ? event.target.value : '')
    }
    const loadTeamUserList = () => {
        if(loading){
            return
        }
        setLoading(true)
        getAllTeamUsers({}).then(res=>{
            if(res.data.users){
                let allTeamUser=res.data.users
                let  users = allTeamUser.map((data) => {
                    return {
                        id: data.value,
                        display: data.label,
                    };
                });
                setAllTeamUser(users)
                setLoading(false)
            }else{
                setLoading(false)
            }
        }).catch(error => {
            setLoading(false)
        })
    }
    const addMention = (id, display) => {};
    const onRemove = (id, display) => {};
    

    return (
        // eslint-disable-next-line react/jsx-pascal-case
        <LinkFile.modal 
            open={open}
            onClose={onClose}
            className={'global-small-modal'}
            buttonText={buttonText()}
            onSubmit={handleSubmit}
            title={isEdit ? 'Edit Note' : 'Add Note'}
        >
           { submitingNote ? (  
            <div style={{width:'100%'}}>
                <Skeleton variant="rect" width={"100%"} height={"300px"} />
            </div>
            ) : ( 
            <Grid container>
                <Grid item xs={12}>
                    <div className="modal-content p-0">
                        <div className="chat__bottom_wrapper update">
                            <div className="tiny-mce">
                                <MentionsInput
                                    cols={30}
                                    rows={5}
                                    className="control-overflow mentionClass"
                                    value={note}
                                    onChange={(e)=>handleChangeNote(e)}
                                    style={defaultStyle}
                                    markup="@[__display__](__id__) "
                                    placeholder={"Add notes and mentions (@username)"}
                                >
                                    <Mention
                                        type="user"
                                        trigger="@"
                                        data={allTeamUser}
                                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                            <div className="user">{highlightedDisplay}</div>
                                        )}
                                        onAdd={addMention}
                                        onRemove={onRemove}
                                        style={mentionStyle}
                                    />
                                </MentionsInput>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )}
        </LinkFile.modal>
    );
}
export default NoteComponent;
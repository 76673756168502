import React, { useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import ContactHeader from "./ContactHeader/ContactHeader";
import DraggableSection from "./DraggableSection";
import { RightSideV2Styles } from "./RightSideV2Styles";
import { RightSideSkeletonV2 } from "./common/RightSideSkeletonV2";
import Miscellaneous from "./Miscellaneous";

const useStyles = makeStyles({
  boxClass: {
    "&.MuiBox-root": {
      width: "100%",
    },
  },
});

const RightSideV2 = (props) => {
  //const { contactDetails } = props || {}
  const [loadingData, setLoadingData] = useState(true);
  const classes = useStyles();

  const { customScrollBar } = RightSideV2Styles();

  useEffect(() => {
    const fakeDataLoading = setTimeout(() => {
      setLoadingData(false);
    }, 2000);

    return () => clearTimeout(fakeDataLoading);
  }, []);

  return (
    <div className="col s12 m12 l4 xl3 lead_owner_collapsible-old lead_owner_collapsible-design-modify white inbox-right-side v2 v2_lead_owner_collapsible inbox-right-side-height-alt">
      <Box className={classes.boxClass}>
        {/* {loadingData ? (
          <RightSideSkeletonV2 />
        ) : ( */}
          <Box
            className={classes.boxClass}
            sx={{ display: "flex", flexDirection: "column", gap: 25 }}
          >
            <ContactHeader
              refreshData={props.refreshData}
              contactId={props.contactId}
              refreshValue={props.refreshValue}
              contactDetails={props.contactDetails}
            />

            <Box
              className={customScrollBar}
              sx={{
                maxHeight: "calc(100vh - 355px)",
                paddingRight: 5,
                paddingBottom: 3,
              }}
            >
              <Miscellaneous
                refreshData={props.refreshData}
                data={null}
                contactId={props.contactId}
                from="inbox"
                sourceList={null}
                refreshValue={props.refreshData}
                contactDetails={props.contactDetails}
              />
              {/* <Miscellaneous
                refreshData={props.refreshData}
                data={null}
                contactId={props.contactId}
                from="inbox"
                sourceList={null}
                refreshValue={props.refreshData}
                contactDetails={props.contactDetails}
              /> */}
              {/* <DraggableSection
                refreshData={props.refreshData}
                contactId={props.contactId}
                refreshValue={props.refreshValue}
              /> */}
            </Box>
          </Box>
        {/* )} */}
      </Box>
    </div>
    // <div className="col s12 m12 l4 xl3 lead_owner_collapsible-old lead_owner_collapsible-design-modify white inbox-right-side v2 v2_lead_owner_collapsible inbox-right-side-height-alt">

    //   //{content}
    // </div>
  );
};

export default RightSideV2;

import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import Utils from '../../../../helpers/Utils';
import * as ACTION from './contactActionTypes'
import * as CONTACT_API from "./contactApi";


function* getNoteModelOpenWatcher() {
    yield takeEvery(ACTION.SHOW_NOTE_MODAL, attemptNoteModelOpen)
}

function* campaignWatcher() {

    yield takeEvery(ACTION.CAMPAIGN_START, campaignStartMiddleware);
    yield takeEvery(ACTION.CAMPAIGN_PAUSE, campaignPauseMiddleware);
    yield takeEvery(ACTION.GET_CONTACT_POLICIES, fetchContactPolicies)
    yield takeEvery(ACTION.GET_POLICY_COMPANY_AGENT, fetchPolicyCompanyAgent)
}


function* attemptNoteModelOpen(action) {
    yield put({type: ACTION.SET_SHOW_NOTE_MODAL, payload:action.payload.status });
}
function* getTaskModelOpenWatcher() {
    yield takeEvery(ACTION.SHOW_TASK_MODAL, attemptTaskModelOpen)
}

function* attemptTaskModelOpen(action) {
    yield put({type: ACTION.SET_SHOW_TASK_MODAL, payload:action.payload.status });
}

function* VideoEmailWatcher() {
    yield takeEvery(ACTION.FETCH_VIDEO_FOLDERS, fetchVideoFoldersFlow);
    yield takeEvery(ACTION.FETCH_VIDEOS, fetchVideosFlow);
    yield takeEvery(ACTION.DELETE_VIDEOS, deleteVideosFlow);
    yield takeEvery(ACTION.FETCH_MESSAGE_TEMPLATES, fetchMessageTemplatesFlow);
}

function* fetchVideoFoldersFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CONTACT_API.fetchVideoFolders, payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({type: ACTION.STORE_VIDEO_FOLDERS, payload: responseData.data});
        }

    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchVideosFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CONTACT_API.fetchVideos, payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({type: ACTION.STORE_VIDEOS, payload: {videos: responseData.data, s3ServerVideoUrl: responseData.s3ServerVideoUrl, videoLoader: false}});
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deleteVideosFlow(action){
    const { payload } = action;
    try {
        const response = yield call(CONTACT_API.deleteVideo, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchMessageTemplatesFlow(action){
    try {
        const response = yield call(CONTACT_API.getMessageTemplate, action.payload);
        let responseData = response.data;
        if (responseData.status === 'success') {
            yield put({ type: ACTION.STORE_MESSAGE_TEMPLATE, payload: responseData.html });
        }
    } catch (error) {
        Utils.handleException(error);
    }
}

// function* getSubUserListWatcher() {
//     yield takeEvery(ACTION.GET_SUBUSER_LIST, attemptGetSubUserList)
// }

// function* attemptGetSubUserList(action) {

//     const { payload } = action;

//     try {
//         const response = yield call(CONTACT_API.getAllTeamUsers, action.payload);
//         let responseData = response.data;

//         if (response.status == 200) {
//             yield put({type: ACTION.SET_SUBUSER_LIST, payload: responseData.users});
//         } else {
//         }
//         if (payload.callback) payload.callback(response?.data);


//     } catch (error) {
//         console.log(error);
//     }
// }


// function* getUserTagListWatcher() {
//     yield takeEvery(ACTION.GET_USERTAG_LIST, attemptGetUserTagList)
// }

// function* attemptGetUserTagList(action) {

//     const { payload } = action;

//     try {
//         const response = yield call(CONTACT_API.getTagList, action.payload);
//         let responseData = response.data;

//         if (response.status == 200) {
//             yield put({type: ACTION.SET_USERTAG_LIST, payload: responseData});
//         } else {
//         }
//         if (payload.callback) payload.callback(response?.data);


//     } catch (error) {
//         console.log(error);
//     }
// }

function* getUserSourceListWatcher() {
    yield takeEvery(ACTION.GET_USERSOURCE_LIST_RIGHTSIDE, attemptGetUserSourceList)
}

function* attemptGetUserSourceList(action) {

    const { payload } = action;

    try {
        const response = yield call(CONTACT_API.getSourceListDropdownForContactTable, action.payload);
        let responseData = response.data;
        if (response.status === 200) {
            yield put({type: ACTION.SET_USERSOURCE_LIST_RIGHTSIDE, payload: responseData});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);


    } catch (error) {
        console.log(error);
    }
}

// function* getUserCustomFieldListWatcher() {
//     yield takeEvery(ACTION.GET_USERCUSTOMFIELD_LIST, attemptGetUserCustomFieldList)
// }

// function* attemptGetUserCustomFieldList(action) {

//     const { payload } = action;

//     try {
//         const response = yield call(CONTACT_API.getUserCustomField, action.payload);
//         let responseData = response.data;
//         if (response.status == 200) {
//             yield put({type: ACTION.SET_USERCUSTOMFIELD_LIST, payload: responseData.data});
//         } else {
//         }
//         if (payload.callback) payload.callback(response?.data);


//     } catch (error) {
//         console.log(error);
//     }
// }

// function* getPipelineListWatcher() {
//     yield takeEvery(ACTION.GET_PIPELINE_LIST, attemptGetPipelineList)
// }

// function* attemptGetPipelineList(action) {

//     const { payload } = action;

//     try {
//         const response = yield call(CONTACT_API.getPipelineList, action.payload);
//         let responseData = response.data;
//         if (response.status == 200) {
//             yield put({type: ACTION.SET_PIPELINE_LIST, payload: responseData.data});
//         } else {
//         }
//         if (payload.callback) payload.callback(response?.data);


//     } catch (error) {
//         console.log(error);
//     }
// }

// function* getCampaignListWatcher() {
//     yield takeEvery(ACTION.GET_CAMPAIGN_LIST, attemptGetCampaignList)
// }

// function* attemptGetCampaignList(action) {

//     const { payload } = action;

//     try {
//         const response = yield call(CONTACT_API.getAllCampaignForDropdown, action.payload);
//         let responseData = response.data;
//         if (response.status == 200) {
//             yield put({type: ACTION.SET_CAMPAIGN_LIST, payload: responseData.data});
//         } else {
            
//         }
//         if (payload.callback) payload.callback(response?.data);


//     } catch (error) {
//         console.log(error);
//     }
// }

function* getContactTagWatcher() {
    yield takeEvery(ACTION.GET_CONTACT_TAG, attemptGetContactTag)
}

function* attemptGetContactTag(action) {
    try {
        const response = yield call(CONTACT_API.getContactTagList, action.payload);
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.SET_CONTACT_TAG, payload: responseData.data});
        } else {
        }
    } catch (error) {
        console.log(error);
    }

}

function* campaignStartMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CONTACT_API.campaignStartApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* campaignPauseMiddleware(action){
    const { payload } = action;
    try {
        const response = yield call(CONTACT_API.campaignPauseApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

  
function* fetchContactPolicies(action){
    const { payload } = action;

    try{
        const response = yield call(CONTACT_API.getContactPolicies, payload);
        if (response.success === true) {
            if (response.data) {
                if (Array.isArray(response.data)) {
                    yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicies: response.data } })
                }
            }
        }else {
            yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicies: [] } })
        }
    }catch (err){
        yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicies: [] } })
    }
}

function* fetchPolicyCompanyAgent(action){
    const { payload } = action;

    try{
        const response = yield call(CONTACT_API.getPolicyCompanyAgentData, payload);
        if (response.success === true) {
            if (response?.data) {
                let contactPolicyCompanyAgentData = {
                    policyTypes: [],
                    insuranceCompanies: [],
                    agents: []
                };

                if (response.data?.policyTypes) {
                    contactPolicyCompanyAgentData.policyTypes = response.data.policyTypes.filter((item)=>(item.status === "ACTIVE"));
                }
                if (response.data?.insuranceCompanies) {
                    contactPolicyCompanyAgentData.insuranceCompanies = response.data.insuranceCompanies;
                }
                if (response.data?.agents) {
                    contactPolicyCompanyAgentData.agents = response.data.agents;
                }

                yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicyCompanyAgentData: contactPolicyCompanyAgentData } });
            }
        }else {
            yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicyCompanyAgentData: {} } })
        }
    }catch (err){
        yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicyCompanyAgentData: {} } })
    }
}


export default function* rightsideMiddleware() {
    yield all([
        // getSubUserListWatcher(),
        // getUserTagListWatcher(),
         getUserSourceListWatcher(),
        // getUserCustomFieldListWatcher(),
        // getPipelineListWatcher(),
        //  getCampaignListWatcher(),
         getContactTagWatcher(),
         campaignWatcher()

    ])
}
const CoreConstants = {
    TRUE: 1,
    FALSE: 0,

    MESSAGE_TYPE: {
        SMS: 1,
        MMS: 2,
        VOICE: 3,
        EMAIL: 4,
        CALL: 5,
        ACTIVITY: 6,
        OUTGOING_CALL: 7,
        WEBHOOK: 9,
        STAGE: 10,
        APPOINTMENT: 12,
        CALL_BRIDGE: 13,
        VIDEO_EMAIL: 14,
        CHAT: 15,
        NOTE: 16,
        GENERAL: 20,
        POST_CARD: 21,
        GREETINGS_CARD: 22,
        GIFT: 23,
        LETTER: 24,
        CALL_RECORD: 25,
    },

    MESSAGE_TEMPLATE: {
        EMAIL: 1,
        SMS: 2,
        VOICE_MAIL: 3,
        VIDEO_EMAIL: 4,
        MMS: 5,
        POST_CARD: 6,
        GREETING_CARD: 7,
        GIFT_CARD: 8,
        LETTER: 9,
    },

    VIDEO_STATUS_WELCOME: 0,
    VIDEO_STATUS_WEBCAM: 1,
    VIDEO_STATUS_WARNING: 2,
    VIDEO_STATUS_PREVIEW: 3,

    //inbox thread
    inboxThread: {
        ACTIVE: 1,
        INACTIVE: 0,

        CONTENT_TYPE_SMS: 1,
        CONTENT_TYPE_MMS: 2,
        CONTENT_TYPE_EMAIL: 4,
        CONTENT_TYPE_CALL: 5,
        CONTENT_TYPE_VOICE_MAIL: 3,
        CONTENT_TYPE_VIDEO_MAIL: 14,
        CONTENT_TYPE_FORM_RESPONSE: 26,
        CONTENT_TYPE_APPOINTMENT: 12,
        CONTENT_TYPE_INCOMING_VOICE_MAIL: 25,
        CONTENT_TYPE_SMART_FORM_RESPONSE: 30,
        INOUT_TYPE_IN: 1,
        INOUT_TYPE_OUT: 2,


        READ: 1,
        UNREAD: 0,

        STATUS_FAIL: 0,
        STATUS_SUCCESS: 1
    },
    Timeline:{
        CONTENT_TYPE_SMS : 1,
        CONTENT_TYPE_MMS : 2,
        CONTENT_TYPE_VOICE : 3,
        CONTENT_TYPE_EMAIL : 4,
        CONTENT_TYPE_CALL : 5,
        CONTENT_TYPE_ACTIVITY : 6,
        CONTENT_TYPE_OUTGOING_CALL : 7,
        CONTENT_TYPE_WEBHOOK : 9,
        CONTENT_TYPE_STAGE : 10,
        CONTENT_TYPE_APPOINTMENT : 12,
        CONTENT_TYPE_CALL_BRIGDE : 13,
        CONTENT_TYPE_VIDEO_EMAIL : 14,
        CONTENT_TYPE_CHAT : 15,
        CONTENT_TYPE_NOTE : 16,
        CONTENT_TYPE_GENERAL : 20,
        CONTENT_TYPE_POST_CARD : 21,
        CONTENT_TYPE_GREETINGS_CARD : 22,
        CONTENT_TYPE_GIFT : 23,
        CONTENT_TYPE_LETTER : 24,
        CONTENT_TYPE_CALL_RECORD : 25,
        CONTENT_TYPE_FORM_RESPONSE : 26,
        CONTENT_TYPE_SMART_FORM_RESPONSE: 30,

   
        INOUT_TYPE_IN : 1,
        INOUT_TYPE_OUT : 2,

        READ :1,
        UNREAD :0,
    
        STATUS_FAIL :2,
        STATUS_SUCCESS:1,
        STATUS_PENDING : 0,
        STATUS_SENT :3,
        STATUS_UNDELIVERED :4,
        STATUS_ACCEPTED : 5,
        STATUS_DELETED : 6,
    
        SENT_IMMEDIATLY : 1,
        SENT_SCHEDULE : 2,

    
        CONTENT_TYPES : {
            0: 'All Conversation',
           1 : 'SMS',
           2 : 'MMS',
           3 : 'Voice',
           4 : 'Email',
           5 : 'Call',
           6 : 'Activity',
           7 : 'Outgoing Call',
           9 : 'Webhook',
           10 : 'Stage',
           12 : 'Appointment',
           13 : 'Call Bridge',
           14 : 'Video Email',
           15 : 'Chat',
           16 : 'Note',
           20 : 'General',
           25 : 'Call Record',
           21 : 'Postcard',
           22 : 'Greeting Card',
           23 : 'Gift',
           24 : 'Letter',
        }
   },
    FORM_BUILDER_V3_COMPONENT_TYPE:{
        THEME: 1,
        SUBMIT_BUTTON: 2,
        HEADING: 3,
        FULL_NAME: 4,
        EMAIL: 5,
        PHONE: 6,
        ADDRESS: 7,
        COMPANY: 8,
        BIRTHDAY: 9,
        NOTE: 10,
        SHORT_ANSWER: 11,
        LONG_ANSWER: 12,
        PHONE_INPUT: 13,
        NUMBER: 14,
        LINK: 15,
        DATE: 16,
        TIME: 17,
        YES_NO: 18,
        RADIO: 19,
        CHECKBOX: 20,
        DROPDOWN: 21,
        FILE_UPLOAD: 22,
        HIDDEN_FIELD: 23,
        PARAGRAPH: 24,
        IMAGE: 25,
        VIDEO: 26,
        AUDIO: 27,
        EMBED: 28,
        PROGRESSBAR: 29
    }
}
export const ActivityType = {
    CALL : '1',
    APOINTMENT : '2',
    TASK: '3',
    DEADLINE: '4',
    EMAIL: '5',
    FOLLOWUP: '6',
    OTHERS: '7'

}
export const leadPermission = {
  REQUEST_TYPE_ADD_COLLABORATOR: 1,
  REQUEST_TYPE_CHANGE_OWNER: 2,
}

const VALIDATION_STATUS_NOT_VERIFIED = 'NOT_VERIFIED';
const VALIDATION_STATUS_VALID = 'VALID';
const VALIDATION_STATUS_INVALID = 'INVALID';
const VALIDATION_STATUS_VERIFYING = 'VERIFYING';

const NUMBER_VALIDATION_TYPE_CALLER_NAME = 'CALLER_NAME';
const NUMBER_VALIDATION_TYPE_CARRIER = 'CARRIER';
const EMAIL_VALIDATION_TYPE_CALLER_NAME = 'VERIFY_EMAIL';

export const LOOKUP_PROVIDER_TYPE_NUMBER = 1;
export const LOOKUP_PROVIDER_TYPE_EMAIL = 2;

export const LOOKUP_TYPE_EMAIL_LOOKUP = "EMAIL_LOOKUP";
export const LOOKUP_TYPE_CARRIER_LOOKUP = "CARRIER_LOOKUP";
export const LOOKUP_TYPE_DNC_LOOKUP = "DNC_LOOKUP";
export const LOOKUP_TYPE_CARRIER_DNC_LOOKUP = "CARRIER_DNC_LOOKUP";
export const LOOKUP_TYPE_CARRIER_EMAIL_LOOKUP = "CARRIER_EMAIL_LOOKUP";
export const LOOKUP_TYPE_EMAIL_DNC_LOOKUP = "EMAIL_DNC_LOOKUP";

const LOOKUP_CALLER_NAME = 63;
const LOOKUP_CARRIER = 64;
const LOOKUP_VERIFY_EMAIL = 65;

export const CONTACT_VALIDATION_STATUS = {
    NOT_VERIFIED: VALIDATION_STATUS_NOT_VERIFIED,
    VALID: VALIDATION_STATUS_VALID,
    INVALID: VALIDATION_STATUS_INVALID,
    VERIFYING: VALIDATION_STATUS_VERIFYING
};

export const CONTACT_VALIDATION_TYPE = {
    CALLER_NAME: NUMBER_VALIDATION_TYPE_CALLER_NAME,
    CARRIER: NUMBER_VALIDATION_TYPE_CARRIER,
    VERIFY_EMAIL: EMAIL_VALIDATION_TYPE_CALLER_NAME
};

export const AGENCY_CARRIER_LOOKUP_CREDIT_TYPE = {
    CALLER_NAME: LOOKUP_CALLER_NAME,
    CARRIER: LOOKUP_CARRIER,
    VERIFY_EMAIL: LOOKUP_VERIFY_EMAIL
};

export const LOOKUP_TYPE = {
    CARRIER_LOOKUP: "CARRIER_LOOKUP",
    EMAIL_LOOKUP: "EMAIL_LOOKUP",
    DNC_LOOKUP: "DNC_LOOKUP",
    SKIP_TRACING_LOOKUP: "SKIP_TRACING_LOOKUP"
};

export const SEARCH_CATEGORY = {
    HOUSE_CURRENT_RESIDENT: "HOUSE_CURRENT_RESIDENT",
    HOUSE_CURRENT_OWNER: "HOUSE_CURRENT_OWNER"
};

export const ACTION = {
    ADD_NEW_CONTACT: "ADD_NEW_CONTACT",
    ADD_UNDER_CONTACT: "ADD_UNDER_CONTACT",
    NO_ACTION: "NO_ACTION"
};

export const AGENCY_BUSINESS_TYPE = {
    INSURANCE: "INSURANCE",
    REGULAR: "REGULAR"
};

export default CoreConstants;
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  addSelectedContact,
  fetchContactAdditionalContacts,
  setContactIdFromDashboard,
  setUser,
  updateMultipleData
} from "../../actions/inboxAction";
import { fetchContactDetails, getUserInfo } from "../../api/contactApi";
import Utils from "../../helpers/Utils";
//moved to cdn
import './inbox.css';
import './inbox-responsive.css';
import LeftSide from "./LeftSide";
import MiddleSide from "./MiddleSide/MiddleSide";
// import RightSide from "./rightSide/RightSide";
import { withTransaction } from '@elastic/apm-rum-react';
// import LogRocket from 'logrocket';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import RightSideV2 from "./RightSideV2/RightSideV2";


// LogRocket.init('cmmavf/pypepro');

// LogRocket.identify(`${Utils.getAccountData('userId')}`, {
//   name: `${Utils.getAccountData('fullName')}`,
//   email: `${Utils.getAccountData('email')}`,

//   // Add your own custom user variables here, ie:
//   // subscriptionType: 'pro'
// });

// Sentry.init({
//     dsn: "https://13a53f96696a4aba9cba399d09261456@o4504393895772160.ingest.sentry.io/4504393898196992",
//     integrations: [new BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

function App(props) {
  const user = useSelector((state) => state.inboxReducer.user);
  const [changeContact, setChangeContact] = useState(false)
  const [noContactDetails, setNoContactDetails] = useState(true)
  const [selectedContactId, setSelectedContactId] = useState(null)
  const [updateUnreadContact, setUpdateUnreadContact] = useState(false)
  const history = useHistory();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const contactId = new URLSearchParams(search).get('contactId');
  const [loadThreads, setLoadThreads] = useState(false)
  const [removeableContactIndex, setRemoveableContactIndex] = useState('')
  // Collapse
  const [changeArrowDir, setChangeArrowDir] = useState(false);
  const [data, setData] = useState(null)
  //const [loadingData, setLoadingData] = useState(true);

  /* TODO: refresh each list manage */
  const [refreshItem, setRefreshItem] = useState('')

  const toggleRightSidebar = (e) => {
    e.preventDefault();
    try {
      document.querySelector("#main__content").classList.toggle("right_sidebar_active");
      setChangeArrowDir(!changeArrowDir)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    document.title = `Inbox | ${Utils.getAccountData('AuthUserAgencyName')}`;
    if (window.setActiveSidebar) {
      window.setActiveSidebar('inbox');
    }

    let inboxToggle = document.querySelector(".inbox_toggle_btn");

    if (inboxToggle) {
      inboxToggle.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector("#main__content").classList.toggle("right_sidebar_active");
      });
    }
  }, [/* noContactDetails */])

  useEffect(() => {
    if (document.querySelector("#main__content")) {
      document.querySelector("#main__content").classList.add("right_sidebar_active");
    }

    // document.querySelector('body').className='has-fixed-sidenav inbox-overflow--hidden';
    document.querySelector('body').classList.add("inbox-overflow--hidden");
    props.updateMultipleData({ selectedContact: null, selectedContactIndex: null })
    loadUserInfo();
  }, []);

  useEffect(() => {
    if (contactId !== undefined && contactId !== null) {
      // props.setContactIdFromDashboard(contactId)
      fetchContactDetails({
        id: contactId
      }).then(response => {
        if (response.data.status === 'success') {
          changeContactCondition(true, contactId, false)
          props.addSelectedContact({
            contact: response.data.data['people'],
            index: null
          })
          // if(window.updateNotificationCount !== undefined){
          //     window.updateNotificationCount()
          // }
        } else {
          window.showNotification("ERROR", response.data.message);
        }
      }).catch((data) => {
        console.log(data)
        window.showNotification("ERROR", "Something went wrong!");
      });
    }
  }, []);

  /**
   * In production, this request won't be made 
   * as the data will be loaded from parent's redux
   */
  const loadUserInfo = () => {
    // make request
    // sendRequest('POST', `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox/get-user-data`, {
    //     'api-key': 'W6GSG6PQ6K5ZCQ1UQT5E',
    // })
    getUserInfo({})
      .then(response => {
        let result = response.data;

        props.storeUser(result);
      }).catch(error => {
        Utils.handleException(error);
      });
  }

  const changeContactCondition = (boolean, contactId, noContact = false, contactIndex = '') => {
    setSelectedContactId(contactId)
    setChangeContact(boolean)
    setNoContactDetails(noContact)
    setRemoveableContactIndex(contactIndex)
    props.fetchContactAdditionalContacts({
      page_no: 1,
      per_page: 100,
      contact_id: contactId,
    });
  }

  const handleRemoveableContact = () => {
    setRemoveableContactIndex('')
  }

  return (
    <React.Fragment>
      <div
        className={`inbox-empty update__empty_inbox v3_update__empty_inbox p-t-10 p-relative ${props.selectedContact === null ? "" : "contact__selected"
          } ${props.rightSidebarVisible ? "active_right_sidebar" : ""} `}
      >
        <div className="row mb-0">
          <LeftSide
            setChangeContact={changeContactCondition}
            handleRemoveableContact={handleRemoveableContact}
            removeableContactIndex={removeableContactIndex}
            updateUnreadContact={updateUnreadContact}
            setUpdateUnreadContact={setUpdateUnreadContact}
            setLoadThreads={setLoadThreads}
          />
          <MiddleSide
            changeContactCondition={changeContactCondition}
            setUpdateUnreadContact={setUpdateUnreadContact}
            loadThreads={loadThreads}
          />
          {/* { (!noContactDetails && props.selectedContact != null) && */}
          {/*    { props.selectedContact != null &&*/}
          {/*        (*/}
          {/*            (Utils.getAccountData("userId") === 1 ||*/}
          {/*                Utils.getAccountData("userId") === 446) ?*/}
          {/*                <RightSideV2*/}
          {/*                    refreshData={(value) => {setRefreshItem(value)}}*/}
          {/*                    data={null}*/}
          {/*                    contactId={props.selectedContact.id}*/}
          {/*                    from={'inbox'}*/}
          {/*                    sourceList={null}*/}
          {/*                    refreshValue={refreshItem}*/}
          {/*                /> :*/}
          {/*                <RightSide*/}
          {/*                    refreshData={(value) => {setRefreshItem(value)}}*/}
          {/*                    data={null}*/}
          {/*                    contactId={props.selectedContact.id}*/}
          {/*                    from={'inbox'}*/}
          {/*                    sourceList={null}*/}
          {/*                    refreshValue={refreshItem}*/}
          {/*                />*/}
          {/*        )*/}
          {/*    }*/}
          {props.selectedContact != null && (
            <RightSideV2
              refreshData={(value) => {
                setRefreshItem(value)
              }}
              data={null}
              contactId={props.selectedContact.id}
              from={"inbox"}
              sourceList={null}
              refreshValue={refreshItem}
              contactDetails={data != null ? data["people"] : null}
            />
          )}
        </div>
      </div>
      {!noContactDetails && (
        <div
          onClick={(e) => toggleRightSidebar(e)}
          className="inbox_toggle_btn switch-icon"
          style={changeArrowDir ? { transform: "rotate(180deg)" } : {}}
        >
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.15118 1.57604L12.1408 6.58429L7.15118 11.5925L6.26288 10.6946L9.72788 7.21664H0.800781V5.95193H9.72788L6.26288 2.47398L7.15118 1.57604ZM12.1408 6.58429V12.9078H13.4008V0.260742H12.1408V6.58429Z"
              fill="white"
            />
          </svg>
        </div>
      )}
    </React.Fragment>
  )
}


const mapStateToProps = state => {
  return {
    selectedContact: state.inboxReducer.selectedContact,
    user: state.inboxReducer.user,
    rightSidebarVisible: state.inboxReducer.rightSidebarVisible,
    contactIdFromDashboard: state.inboxReducer.contactIdFromDashboard,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeUser: (params, callBack) => dispatch(setUser(params, callBack)),
    setContactIdFromDashboard: (params) => dispatch(setContactIdFromDashboard(params)),
    addSelectedContact: (params) => dispatch(addSelectedContact(params)),
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
    fetchContactAdditionalContacts: (params) => dispatch(fetchContactAdditionalContacts(params))
  };
}

let Inbox = connect(mapStateToProps, mapDispatchToProps)(App)
export default withTransaction('Inbox', 'component')(Inbox);

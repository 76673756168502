import React, { createContext, useEffect } from "react";
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from 'react-router-dom';
import { createGenerateClassName, StylesProvider } from "@material-ui/styles";
import { init as initApm } from '@elastic/apm-rum'

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});

const generateClassName = createGenerateClassName({
  productionPrefix: 'inbox',
});

// initApm({

//     // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
//     serviceName: process.env.REACT_APP_APM_SERVICENAME,

//     // Set custom APM Server URL (default: http://localhost:8200)
//     serverUrl: process.env.REACT_APP_APM_SERVERURL,

//     // Set service version (required for sourcemap feature)
//     serviceVersion: process.env.REACT_APP_APM_SERVICEVERSION,

//       // Set the service environment
//     environment: process.env.REACT_APP_APM_ENVIRONMENT,

//     Type: ['history'] 

//   })

function App({ history = defaultHistory }) {

  useEffect(() => {
    // if(window.innerWidth <= 1200){
    if (window.innerWidth <= 1400) {
      window.setCollapsedMenu(true)
    }
  })
  return (
    <HistoryProvider.Provider value={history}>
      <Router history={history}>
        <StylesProvider generateClassName={generateClassName}>
          <Routes />
        </StylesProvider>
      </Router>
    </HistoryProvider.Provider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import BasicSelect from "./custom/BasicSelect";
import BasicInput from "./custom/BasicInput";
import BasicInputForIcon from "./custom/BasicInputForIcon";
import { Box, Checkbox, Divider, FormControlLabel, InputAdornment, makeStyles, Typography } from "@material-ui/core";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { Add } from "@material-ui/icons";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { getUserSourceList, setUserSourceList } from "../../rightSide/redux/contactAction";
import { connect } from "react-redux";
import { changeContactSource } from "../../rightSide/redux/contactApi";
import { ErrorMessage, ModalContactChip, ModalHead, ModalTitle } from "./PolicyStyle";
import Utils from "../../../../helpers/Utils";
import { createUserPolicy, updateContactPolicy } from "../../../../api/contactApi";

const useStyle = makeStyles({
  formLabel: {
    fontWeight: "600 !important",
    color: "#181f48",
    marginBottom: "0px !important",
  },
  addNewButton: {
    color: " white !important",
    padding: "7px 12px 8px !important",
    fontWeight: "600 !important",
    borderRadius: "5px !important",
    textTransform: "capitalize !important",
    backgroundColor: " #316AFF !important",
    width: "135px",
  },
  checkBoxStyle: {
    color: "#0E1B3D",
    fontSize: "16px !important",
    fontWeight: "600",
  },
  addDealButton: {
    color: " white !important",
    padding: "7px 12px 8px !important",
    fontWeight: "600 !important",
    borderRadius: "5px !important",
    textTransform: "capitalize !important",
    backgroundColor: " #316AFF !important",
  },
  cancelButton: {
    color: " #316AFF !important",
  },
});

const AddPolicyModal = (props) => {

  const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState("");
  const [policyNumber, setPolicyNumber] = useState(null);
  const [policyLink, setPolicyLink] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [fullPremium, setFullPremium] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(Utils.getAccountData('userId'));
  const [contactLabel, setContactLabel] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [sourceList, setSourceList] = useState(null);
  const [sourceId, setSourceId] = useState("");
  const [formDataError, setFormDataError] = useState({
    selectedPolicy: "",
    selectedInsuranceCompany: "",
    selectedAgent: "",
    policyLink:""
  });

  useEffect(() => {
    updateContactTitleAndSetSource()

    if (props.policyId) {
      const filteredData = props.policyData.filter((data) => data.id === props.policyId);
      if (filteredData[0]) {
        props.setSelectedPolicy(filteredData[0].policy_id);
        setSelectedInsuranceCompany(filteredData[0].insurance_company_id);
        setEffectiveDate(filteredData[0].effective_date);
        setExpirationDate(filteredData[0].expiration_date);
        setSelectedAgent(filteredData[0].agent_id);
        setFullPremium(filteredData[0].price);
        setPolicyLink(filteredData[0].policy_link);
        setPolicyNumber(filteredData[0].policy_number);
      
      }
    } else {
      let today = new Date();
      const formattedDate = today.toISOString().split("T")[0];
      const expireDate = new Date(today.setFullYear(today.getFullYear() + 1));
      const year = expireDate.getFullYear();
      const month = String(expireDate.getMonth() + 1).padStart(2, "0");
      const day = String(expireDate.getDate()).padStart(2, "0");

      const formattedExpireDate = `${year}-${month}-${day}`;

      setEffectiveDate(formattedDate);
      setExpirationDate(formattedExpireDate);
      setFullPremium(0);
      
    }
  }, []);

  const updateContactTitleAndSetSource = () => {
    if (props.contact) {
      let label = "";

      if (props.contact.first_name) {
        label += props.contact.first_name;
      }

      if (props.contact.last_name) {
        if (label) {
          label += " ";
        }
        label += props.contact.last_name;
      }

      if (!label) {
        label = props.contact.email;
      }

      if (!label) {
        label = props.contact.number;
      }

      setContactLabel(label);
    }
    if (props.contact && props.contact.source) {
      setSourceId(props.contact.source);
    }

    if (props.storedSourceList) {
      setSourceList(props.storedSourceList);
    } else {
      getSourceListDropdown();
    }
  };


  const getSourceListDropdown = async () => {
    await props.fetchUserSourceList({}, (callback) => {
      setSourceList(callback);
    });
  };



  const updateSourceOfContact = (id) => {
    setSourceId(id);
    changeContactSource({
      contact_id: props.contact.id,
      source_id: id,
    }).then((res) => {
      if (res.status === 200) {
        window.showNotification("SUCCESS", "Source Updated");
      } else {
        window.showNotification("ERROR", "Something was wrong, please try to reload the page.");
      }
    });
  };


  const handleAddNewPolicyType = () => {
    props.setOpenAddNewPolicyTypeModal(true);
     resetFormData();
  };

 

  const resetFormData = () => {
    setFormDataError({
      selectedPolicy: "",
      selectedInsuranceCompany: "",
      selectedAgent: "",
      policyLink:""
    });
  };

  const validateData = () => {
    let inValid = false;
    const errors = {
      selectedPolicy: "",
      selectedInsuranceCompany: "",
      selectedAgent: "",
      policyLink:""
    };
    if (props.selectedPolicy === "") {
      inValid = true;

      errors.selectedPolicy = "Policy is required";
    }

    if (selectedInsuranceCompany === "") {
      inValid = true;

      errors.selectedInsuranceCompany = "Insurance company is required";
    }

    if (selectedAgent === "") {
      inValid = true;

      errors.selectedAgent = "Agent is required";
    }
    if(policyLink){
      if (!Utils.validURL(policyLink)) {
        inValid = true;
  
        errors.policyLink = "Invalid URL";
      }
     }
    if (inValid) {
      setFormDataError(errors);
    }
    return inValid;
  };

  const handleSubmitPolicy = async () => {
    try {
      if (validateData()) {
        return;
      }
      setSubmitting(true);
     
        if (props.policyId) {
          const payload = {
            contact_policy_id: props.policyId,
            contact_id: props.contact.id,
            policy_id: props.selectedPolicy,
            insurance_company_id: selectedInsuranceCompany,
            effective_date: effectiveDate,
            expiration_date: expirationDate,
            price: fullPremium,
            agent_id: selectedAgent,
            policy_link: policyLink,
            policy_number: policyNumber
          };
          const response = await updateContactPolicy(payload);
          if (response && response.success) {
            window.showNotification("SUCCESS", response.message);
           
          }
        } else {
          const payload = {
            contact_id: props.contact.id,
            policy_id: props.selectedPolicy,
            insurance_company_id: selectedInsuranceCompany,
            effective_date: effectiveDate,
            expiration_date: expirationDate,
            price: fullPremium,
            agent_id: selectedAgent,
          };
          const response = await createUserPolicy(payload);
          if (response && response.success) {
            window.showNotification("SUCCESS", response.message);
          
          }
      }

      props.handleCloseModal(true);
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };
  const handlePremiumValue=(e)=>{
    const inputValue = parseFloat(e.target.value);
    if (inputValue >= 0 || e.target.value === "") {
        setFullPremium(inputValue);
    }

  }
  const handlePolicyType=(e)=>{
    props.setSelectedPolicy(e.target.value)
    setFormDataError((prevState)=>({ ...prevState, selectedPolicy: ""}));
  }

  const handleInsuranceCompany=(e)=>{
    setSelectedInsuranceCompany(e.target.value)
    setFormDataError((prevState)=>({ ...prevState, selectedInsuranceCompany: ""}));
  }

  const handleAgents=(e)=>{
    setSelectedAgent(e.target.value)
    setFormDataError((prevState)=>({ ...prevState, selectedAgent: ""}));
  }
  const classes = useStyle();
  const activeColor = "#36454F";
  const placeholderColorForDate = "rgb(8 40 82 / 42%)";

const handleLink = (e) => {
  setPolicyLink(e.target.value);
  setFormDataError((prevState) => ({ ...prevState, policyLink: "" }));
};
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={()=>props.handleCloseModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <ModalHead>
          <ModalTitle>{props.policyId? "Edit Policy" : "Add a New Policy"}</ModalTitle>
          <Divider style={{ width: "165px", backgroundColor: "#316aff" }} />
          {contactLabel && (
            <ModalContactChip>
              <BootstrapTooltip placement={"top"} arrow title={contactLabel}>
                <span>{contactLabel}</span>
              </BootstrapTooltip>
            </ModalContactChip>
          )}
        </ModalHead>
        <Box minWidth={600} p={"16px"}>
          <Box>
            <Typography style={{ height: "unset !important" }} className={classes.formLabel}>Policy Type</Typography>
            <Box display={"flex"} alignItems={"center"} gridGap={16}>
              <BasicSelect
                fullWidth
                name='policy'
                defaultText='Select an option'
                value={props.selectedPolicy}
                options={props.policies}
                onChange={handlePolicyType}
                mapping={{
                  label: "label",
                  value: "value",
                }}
              />
              <Button
                onClick={handleAddNewPolicyType}
                variant='contained'
                className={classes.addNewButton}
                startIcon={<Add />}
              >
                Add New
              </Button>
            </Box>
            {formDataError.selectedPolicy && <ErrorMessage>{formDataError.selectedPolicy}</ErrorMessage>}
          </Box>
          <Box mt={2}>
            <Typography style={{ height: "unset !important" }} className={classes.formLabel}>Insurance Company</Typography>
            <BasicSelect
                fullWidth
                name='company'
                defaultText='Select an option'
                value={selectedInsuranceCompany}
                options={props.companies}
                onChange={handleInsuranceCompany}
                mapping={{
                  label: "label",
                  value: "value",
                }}
              />
         
            {formDataError.selectedInsuranceCompany && (
              <ErrorMessage>{formDataError.selectedInsuranceCompany}</ErrorMessage>
            )}
          </Box>
          {/* <Box mt={2}>
              <Typography style={ height: "unset !important  }className={classes.formLabel}>Insurance Category</Typography>
              <BasicSelect
                fullWidth
                name='category'
                defaultText='Select category'
                value={selectedInsuranceCategory}
                options={policies}
                onChange={(e) => setSelectedInsuranceCategory(e.target.value)}
                mapping={{
                  label: "label",
                  value: "id",
                }}
              />
            </Box> */}
          <Box>
          <Box display={"flex"} gridGap={16} mt={2}>
              <Box width={"100%"}>
                <Typography style={{ height: "unset !important" }}  className={classes.formLabel}>Policy Number</Typography>
                <BasicInput
                  type='number'
                  onChange={(e) => setPolicyNumber(e.target.value)}
                  fullWidth
                  placeholder='Enter Policy Number'
                  value={policyNumber}
                />
              </Box>
              <Box width={"100%"}>
                <Typography style={{ height: "unset !important" }}  className={classes.formLabel}>Policy Link</Typography>
                <BasicInput 
                 onChange={handleLink}
                fullWidth 
                placeholder='Enter Policy Link'
                value={policyLink}
                 />
                {formDataError.policyLink && (
                  <ErrorMessage>{formDataError.policyLink}</ErrorMessage>
            )}
              </Box>
            </Box>
            <Box display={"flex"} gridGap={16} mt={2}>
              <Box width={"100%"}>
                <Typography style={{ height: "unset !important" }} className={classes.formLabel}>Effective Date</Typography>
                <BasicInput
                  fullWidth
                  value={effectiveDate}
                  type='date'
                  onChange={(e) => setEffectiveDate(e.target.value)}
                  style={{ color: effectiveDate ? activeColor : placeholderColorForDate }}
                />
              </Box>
              <Box width={"100%"}>
                <Typography style={{ height: "unset !important" }} className={classes.formLabel}>Expiration Date</Typography>
                <BasicInput
                  fullWidth
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  type='date'
                  style={{ color: expirationDate ? activeColor : placeholderColorForDate }}
                />
              </Box>
            </Box>
            <Box display={"flex"} gridGap={16} mt={2}>
              <Box width={"50%"}>
                <Typography style={{ height: "unset !important" }} className={classes.formLabel}>Full Premium</Typography>
                <BasicInputForIcon
                  type='number'
                  startAdornment={
                    <InputAdornment position='start'>
                      <AttachMoneyIcon
                        style={{ fontSize: "20px", color: fullPremium >= 0 ? activeColor : placeholderColorForDate }}
                      />
                    </InputAdornment>
                  }
                  fullWidth
                  value={fullPremium}
                  placeholder='Enter Premium'
                  onChange={handlePremiumValue}
                />
              </Box>
              <Box width={"50%"}>
                <Typography style={{ height: "unset !important" }} className={classes.formLabel}>Lead Source</Typography>
                <BasicSelect
                  fullWidth
                  name='leadSource'
                  defaultText='Select an option'
                  value={sourceId}
                  options={sourceList?.payload?sourceList.payload:sourceList}
                  onChange={(e) => updateSourceOfContact(e.target.value)}
                  mapping={{
                    label: "source_title",
                    value: "id",
                  }}
                />
              </Box>
            </Box>
            <Box mt={2}>
              <Typography style={{ height: "unset !important" }} className={classes.formLabel}>Agent</Typography>
              <BasicSelect
                fullWidth
                name='agent'
                defaultText='Select an agent'
                value={selectedAgent}
                options={props.agents}
                onChange={handleAgents}
                mapping={{
                  label: "label",
                  value: "value",
                }}
              />
            </Box>
            {formDataError.selectedAgent && <ErrorMessage>{formDataError.selectedAgent}</ErrorMessage>}
          {!props.policyId &&<Box mt={4}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.cancelButton}
                  size={"small"}
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
              }
              className={classes.checkBoxStyle}
              label=' Include this manual entry in sales reporting and goal calculations'
            />
            <Typography style={{ height: "unset !important", fontSize: "12px", paddingLeft: "28px", color: "#808080", fontWeight: "400" }}>
              For accurate reporting you must associate this entry with a deal
            </Typography>
          </Box>}
            <Box display={"flex"} justifyContent={"end"} gridGap={16} mt={2}>
              <Button className={classes.cancelButton} onClick={() => props.handleCloseModal(false)}>
                Cancel
              </Button>
              <Button
                disabled={submitting}
                variant='contained'
                className={classes.addDealButton}
                onClick={handleSubmitPolicy}
              >
                {!submitting?'Save':'Saving...'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    storedSourceList: state.rightsideReducer.sourceList,
    contact: state.rightsideReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserSourceList: (params, callback) => dispatch(getUserSourceList(params, callback)),
    storeUserSourceList: (params, callback) => dispatch(setUserSourceList(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPolicyModal);

import { Grid, makeStyles, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import {
  InsuraceCardBody,
  InsuraceCardFooter,
  InsuraceFlexBox,
  InsuraceFlexBoxForHeader,
  InsuraceStatusForActive,
  InsuraceStatusForInActive,
  InsuraceTitle,
  InsuranceCard,
  InsuranceCardWrapper,
  InsuranceHeader,
  InsuranceHeaderFlexBox,
  InsuranceIconContainer,
} from "./PolicyStyle";
import EditPolicyPopOver from "./EditPolicyPopOver";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
const useStyle = makeStyles({
  textStyle: {
    maxHeight: "2.5em", 
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    fontWeight: 600,
  },
});

const EachInsurance = ({ item, dataObject, fetchContactPolicyData, setPolicyId,setOpenPolicyModal}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };
  
  const formatUrl = (url) => {
    if((url.startsWith('http://')) || (url.startsWith('https://')))
    {
      return url;
    } else {
      return `https://${url}`;
    }
  };
  const dateTimeConversion = (date) => {
    if (window.formatDateWithoutTimezoneChange) {
      return window.formatDateWithoutTimezoneChange(date, 'UTC', 'MM-DD-YYYY');
    } else {
      return date ;
    }
  };  
  const expirationFormattedDate = dateTimeConversion(item.expiration_date);
  const effectiveFormattedDate = dateTimeConversion(item.effective_date);

  const openEditPolicyPopOver = Boolean(anchorEl);
  const id = openEditPolicyPopOver ? "simple-popover" : undefined;
  const classes=useStyle()
  return (
    <InsuranceCard>
      <InsuranceCardWrapper>
        <InsuranceHeader>
          <InsuraceFlexBoxForHeader>
            <InsuraceTitle>{dataObject?.policyType[item?.policy_id]}</InsuraceTitle>
             <InsuranceHeaderFlexBox>
              {item.status === "ACTIVE" ? (
                <InsuraceStatusForActive>ACTIVE</InsuraceStatusForActive>
              ) : (
                <InsuraceStatusForInActive>INACTIVE</InsuraceStatusForInActive>
              )}
              <InsuranceIconContainer aria-describedby={id} onClick={handleOpenPopOver}>
                <MoreVertIcon />
              </InsuranceIconContainer>
            </InsuranceHeaderFlexBox>
          </InsuraceFlexBoxForHeader>
          <InsuraceFlexBox>
            <Typography style={{ fontWeight: 300, fontSize: "13px" }}>
              Policy #: &nbsp;<span style={{fontSize:'12px'}}>{item.policy_number}</span>
            </Typography>
            <Typography style={{ fontSize: "13px",fontWeight: 400 }}>Full Premium&nbsp;:&nbsp; ${item.price}</Typography>
          </InsuraceFlexBox>
        </InsuranceHeader>
        <InsuraceCardBody>
          <Grid container spacing={1}>
            <Grid item md={12} lg={6} xl={6} >
              <Typography style={{ color: "#929da9", fontSize: "11px", wordBreak: "break-word", height: "18px" }}>
                EFFECTIVE DATE
              </Typography>
              <Typography style={{ fontWeight: 500, fontSize: "11px" }}>{effectiveFormattedDate}</Typography>
            </Grid>
            <Grid item md={12} lg={6} xl={6}>
              <Typography style={{ color: "#929da9", fontSize: "11px", wordBreak: "break-word", height: "18px" }}>
                EXPIRATION DATE
              </Typography>
              <Typography style={{ fontWeight: 500, fontSize: "11px" }}>{expirationFormattedDate}</Typography>
            </Grid>
            <Grid item md={12} lg={6} xl={6}>
              <Typography style={{ color: "#929da9", fontSize: "11px", wordBreak: "break-word", height: "18px" }}>
                INSURANCE COMPANY
              </Typography>
              <BootstrapTooltip title={dataObject?.company[item?.insurance_company_id]} arrow placement='top'>
              <Typography className={classes.textStyle} style={{ fontSize: "11px" }}>
                {dataObject?.company[item?.insurance_company_id]}
              </Typography>
              </BootstrapTooltip>
            </Grid>
            {item.policy_link && (
              <Grid item md={12} lg={6} xl={6}>
                <a href={formatUrl(item.policy_link)} target='_blank' rel='noopener noreferrer'>
                  <LinkIcon style={{ rotate: "135deg", fontSize: "28px" }} />
                </a>
              </Grid>
            )}
          </Grid>
        </InsuraceCardBody>
        <InsuraceCardFooter>
          <InsuraceFlexBoxForHeader>
            <Typography style={{ fontWeight: 500, fontSize: "14px" }}>AGENT</Typography>
            <Typography style={{ fontWeight: 500, fontSize: "14px" }}>{dataObject?.agent[item?.agent_id]}</Typography>
          </InsuraceFlexBoxForHeader>
        </InsuraceCardFooter>
      </InsuranceCardWrapper>
   
      {openEditPolicyPopOver && (
        <EditPolicyPopOver
          open={openEditPolicyPopOver}
          setPolicyId={setPolicyId}
          id={id}
          handleClose={handleClosePopOver}
          anchorEl={anchorEl}
          fetchContactPolicyData={fetchContactPolicyData}
          item={item}
          setOpenPolicyModal={setOpenPolicyModal}
        />
      )}
    
    </InsuranceCard>
  );
};

export default EachInsurance;

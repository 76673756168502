import React, {useState, useEffect} from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Avatar from '@material-ui/core/Avatar';
import { FormControlLabel } from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';
import { deleteNoteRightSide, getTimeline } from '../../../../api/contactApi';
import NoteList from './NoteList';
import './right-side-note.css'
import NoteComponent from '../noteNew/NoteComponent';
import StyledAccordion from "../../../common/StyledAccordion";
import DynamicSkeleton from '../common/DynamicSkeleton';

const ContactNotes = (props) =>  {
    const [notes, setNotes] = useState([])
    const [showAddNoteModal, isShowAddNoteModal] = useState(false)
    const [show, setShow] = useState(false)
    const [editData, setEditData] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editIndex, setEditIndex] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if(show){
            getContactNotes()
        }
        else{
            setEditData(null)
            setIsLoading(true)
            setIsEdit(false)
            setEditIndex(null)
        }
    }, [show])

    const handleToggleAccordion = () => {
        setShow(!show)
    };

    const toggleAddNoteModal = (afterEdit = false)=> {
        isShowAddNoteModal(!showAddNoteModal)
        if(isEdit && !afterEdit){
            setIsEdit(false)
            setEditData(false)
            setEditIndex(null)
        }
    }

    const storeEditData = (data, index) => {
        setEditData(data)
        if(data === null){
            setIsEdit(false)
            setEditIndex(null)
        }
        else{
            setIsEdit(true)
            setEditIndex(index)
            isShowAddNoteModal(true)
        }
    }

    const deleteItem = (id, index) => {
        deleteNoteRightSide({
            id: id
        }).then(res => {
            getContactNotes()
            window.refreshConersation()
        }).catch(error => {
            if(window.showNotification !== undefined){
                window.showNotification("ERROR", "Something went wrong!");
            }
        })
    }

    const getContactNotes = () => {
        getTimeline({
            contact_id: props.contact_id,
            content_type: 16,
            page: 1,
            skip: 0
        }).then(res => {
            let response = res.data;
            setNotes(response.data)
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        })
    }

    const renderNoteList = () => {
        let showNotes = []
        notes.map((item, index) => {
            showNotes.push(
                <NoteList 
                    key={index+"-"+item.message}
                    index={index}
                    data={item}
                    storeEditData={storeEditData}
                    deleteItem={deleteItem}
                />
            )
        })
        return showNotes;
    }

    const renderMain = () => {
        if(isLoading){
            return(
                <DynamicSkeleton count={1}/>
            )
        }
        else{
            if(notes.length === 0){
                return (
                    <div>
                        {/* <span style={{display:"flex",justifyContent:"center",paddingTop:"10px"}}>No note added!</span> */}
                    </div>
                )
            }
            else{
                return renderNoteList()
            }                
        }
    }

    const updateAddNote = (message = "") => {
        if(isEdit){
            let allNotes = [...notes]
            allNotes[editIndex].message = message;
            setNotes(allNotes)
            storeEditData(null);
        }
        else{
            getContactNotes();
        }
        props.refreshData('collaborator')
    }

    return (
        <StyledAccordion expanded={show}>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={handleToggleAccordion}
            >
                <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                        <div className="collaborator__list__parant">
                            <div className="collaborator__list__left"> 
                                <span className="collaborator__left__icon">
                                    <NoteIcon />
                                </span> 
                                <span className="collaborator__list--title text-dark-blue">Notes</span>
                            </div>
                            <div className="collaborator__list__right" onClick={(event) => event.stopPropagation()}> 
                                <Avatar onClick={toggleAddNoteModal} style={{ marginLeft: 10 }} ><i className="material-icons rightsidebar__list__addicon">add</i></Avatar>
                            </div>
                        </div>
                    }
                />
            </AccordionSummary>
            <AccordionDetails className="accordion__parent right-side-note">
                {renderMain()}
            </AccordionDetails>

        {showAddNoteModal &&
            // <AddNote 
            //     open={showAddNoteModal}
            //     onClose={toggleAddNoteModal}
            //     data={editData}
            //     isEdit={isEdit}
            //     successCallback={updateAddNote}
            //     contactId={props.contact_id}
            // />
            <NoteComponent 
                open={showAddNoteModal}
                onClose={toggleAddNoteModal}
                data={editData}
                isEdit={isEdit}
                successCallback={updateAddNote}
                contactId={props.contact_id}
            />
        }
        </StyledAccordion>
    )
}
export default ContactNotes;